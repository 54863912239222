import React from 'react';
import { useRecoilValue } from 'recoil';
import { Text, Box, Input, useToast } from '@chakra-ui/react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import cuid from 'cuid';

import CustomDrawer from '~/components/UI/Drawer';
import { Menu, Week } from '~/types/store';
import { FirebaseError } from '~/types/error';
import { selectedStoreIdAtom } from '~/recoil/atom';
import { addNewMenuInStore } from '~/firebase/store';

type NewMenuDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

type NewMenu = {
  name: string;
  code: string;
};

const defaultAllDaySchedule = {
  [Week.SUN]: [{ open: 0, close: 1439 }],
  [Week.MON]: [{ open: 0, close: 1439 }],
  [Week.TUE]: [{ open: 0, close: 1439 }],
  [Week.WED]: [{ open: 0, close: 1439 }],
  [Week.THU]: [{ open: 0, close: 1439 }],
  [Week.FRI]: [{ open: 0, close: 1439 }],
  [Week.SAT]: [{ open: 0, close: 1439 }],
};

const NewMenuDrawer: React.FC<NewMenuDrawerProps> = ({ isOpen, onClose }) => {
  const storeId = useRecoilValue(selectedStoreIdAtom);

  const methods = useForm<NewMenu>();

  const { control, handleSubmit } = methods;

  const toast = useToast();

  const handleSaveNewMenu = async ({ code, name }: NewMenu) => {
    const newMenuId = cuid();
    const newMenu: Menu = {
      allowDelivery: false,
      allowPickup: true,
      allowPreorder: true,
      allowPublish: true,
      name,
      code,
      kitchenId: `${storeId}-${code}`,
      schedule: defaultAllDaySchedule,
      categories: [],
    };
    try {
      await addNewMenuInStore({ storeId, newMenuId, newMenu });
      toast({
        position: 'top',
        title: '已新增菜單',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error: any) {
      console.log(error);
      toast({
        position: 'top',
        title: error.message === FirebaseError.DOC_HAS_EXIST ? '已存在的專櫃編號' : '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <CustomDrawer
          title="專櫃菜單設定"
          isOpen={isOpen}
          onClose={onClose}
          onSave={handleSubmit(handleSaveNewMenu)}
        >
          <Box as="label" justifyContent="space-between">
            <Text mb="0.2rem" htmlFor="name" color="#8F8F8F" fontSize="md">
              專櫃名稱
            </Text>
            <Controller
              control={control}
              name="name"
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <Input {...field} isInvalid={!!error} errorBorderColor="admin.error" />
              )}
              rules={{ required: true }}
            />
          </Box>
          <Box as="label" justifyContent="space-between">
            <Text mb="0.2rem" htmlFor="code" color="#8F8F8F" fontSize="md">
              專櫃編號
            </Text>
            <Controller
              control={control}
              name="code"
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <Input {...field} isInvalid={!!error} errorBorderColor="admin.error" />
              )}
              rules={{ required: true }}
            />
          </Box>
        </CustomDrawer>
      </FormProvider>
    </>
  );
};

export default NewMenuDrawer;
