import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { storeListAtom, selectedStoreIdAtom } from '~/recoil/atom';

export default function useSelectedStoreId() {
  const storeList = useRecoilValue(storeListAtom);
  const setSelectedStoreId = useSetRecoilState(selectedStoreIdAtom);

  useEffect(() => {
    setSelectedStoreId(storeList[0]?.id || '');
  }, [storeList]);
}
