import { WsOrderStatusType } from '../type';

export const statusConvert = ({
  status,
  allowPaymentNotRefund,
}: {
  status: WsOrderStatusType;
  allowPaymentNotRefund: boolean;
}) => {
  switch (status) {
    case WsOrderStatusType.UNPAID:
      return '未付款';
    case WsOrderStatusType.ACCEPTED:
      return '備料中';
    case WsOrderStatusType.COMPLETED:
      return '已完成';
    case WsOrderStatusType.FAILED:
      return allowPaymentNotRefund ? '客戶棄單' : '已取消';
  }
};
