import { atom } from 'recoil';
import firebase from 'firebase';

// Uncaught TypeError: Cannot freeze: dangerouslyAllowMutability
// https://github.com/facebookexperimental/Recoil/issues/406
const authUser = atom<firebase.User | null>({
  key: 'authUser',
  default: null,
  dangerouslyAllowMutability: true,
});

export default authUser;
