import { firebaseFirestore } from './initialize';
import { OrderDisplayObj, Order } from '~/components/TodayOrders/type';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';

export const recentOrderListener = ({
  storeId,
  baseDate,
  orderUpdater,
}: {
  storeId: string;
  baseDate: Date;
  orderUpdater: (orders: Array<OrderDisplayObj>) => void;
}) => {
  return firebaseFirestore
    .collection('orders')
    .where('store.id', '==', storeId)
    .where('createdAt', '>=', baseDate)
    .orderBy('createdAt', 'desc')
    .onSnapshot(
      (docs) => {
        let ordersList: Array<OrderDisplayObj> = [];

        docs.forEach((doc) => {
          const order = doc.data() as Order;

          const newOrder = {
            ...order,
            id: doc.id,
            createdAt: format(order.createdAt.toDate(), 'MM/dd HH:mm'),
            preOrderAt: format(order.preOrderAt.toDate(), 'MM/dd HH:mm'),
          };
          ordersList.push(newOrder);
        });
        orderUpdater(ordersList);
      },
      (error) => console.error(`recent order listener error(storeId: ${storeId})`, error),
    );
};

export const preOrderListener = ({
  storeId,
  baseDate,
  orderUpdater,
}: {
  storeId: string;
  baseDate: Date;
  orderUpdater: (orders: Array<OrderDisplayObj>) => void;
}) => {
  return firebaseFirestore
    .collection('orders')
    .where('store.id', '==', storeId)
    .where('preOrderAt', '>=', baseDate)
    .onSnapshot(
      (docs) => {
        let ordersList: Array<OrderDisplayObj> = [];

        docs.forEach((doc) => {
          const order = doc.data() as Order;
          if (isBefore(order.createdAt.toDate(), baseDate)) {
            const newOrder = {
              ...order,
              id: doc.id,
              createdAt: format(order.createdAt.toDate(), 'MM/dd HH:mm'),
              preOrderAt: format(order.preOrderAt.toDate(), 'MM/dd HH:mm'),
            };
            ordersList.push(newOrder);
          }
        });
        orderUpdater(ordersList);
      },
      (error) => console.error(`preOrder order listener error(storeId: ${storeId})`, error),
    );
};
