import { useState } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Link,
  Center,
  useDisclosure,
} from '@chakra-ui/react';
import { UpDownIcon } from '@chakra-ui/icons';

import OrderDetail from './OrderDetail';
import { FilterActionType, FilterAction } from '~/components/TodayOrders/utils';
import { statusConvert } from './utils';
import { OrderDisplayObj } from './type';

const OperationTable = ({
  filteredOrders,
  filterDispatch,
}: {
  filteredOrders: OrderDisplayObj[];
  filterDispatch: React.Dispatch<FilterActionType>;
}) => {
  const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpenDetailDrawer = (id: string) => {
    setSelectedOrder(id);
    onOpen();
  };

  const handleCloseDetailDrawer = () => {
    setSelectedOrder(null);
    onClose();
  };

  return (
    <Box bg="white" overflowX="auto">
      {filteredOrders.length ? (
        <>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr h={10}>
                <Th>狀態</Th>
                <Th>訂單編號</Th>
                <Th
                  minW="8rem"
                  cursor="pointer"
                  onClick={() => filterDispatch({ type: FilterAction.ORDER_CREATED_AT })}
                >
                  下單時間
                  <UpDownIcon ml={1} pb={0.5} color="admin.primary" />
                </Th>
                <Th
                  minW="8rem"
                  cursor="pointer"
                  onClick={() => filterDispatch({ type: FilterAction.ORDER_PREORDER_AT })}
                >
                  取餐時間
                  <UpDownIcon ml={1} pb={0.5} color="admin.primary" />
                </Th>
                <Th>消費者電話</Th>
                <Th>訂單金額</Th>
                <Th>發票號碼</Th>
                <Th>金流序號</Th>
              </Tr>
            </Thead>
            <Tbody bg="white">
              {filteredOrders.map(
                ({
                  id,
                  allowPaymentNotRefund,
                  customer: { phoneNumber: customerPhoneNumber },
                  total,
                  createdAt,
                  status,
                  orderNumber,
                  preOrderAt,
                  paymentInfo: { gatewayTransactionId = '-' },
                  invoice,
                }) => (
                  <Tr key={id} h={14} backgroundColor={'white'}>
                    <Td fontSize="xs">{statusConvert({ status, allowPaymentNotRefund })}</Td>
                    <Td
                      fontSize="xs"
                      color="admin.primary"
                      onClick={() => handleOpenDetailDrawer(id)}
                    >
                      {orderNumber}
                    </Td>
                    <Td fontSize="xs">{createdAt}</Td>
                    <Td fontSize="xs">{preOrderAt}</Td>
                    <Td fontSize="xs">{customerPhoneNumber}</Td>
                    <Td fontSize="xs">{total}</Td>
                    <Td fontSize="xs">{invoice?.invoiceNumber || '-'}</Td>
                    <Td fontSize="xs">{gatewayTransactionId}</Td>
                  </Tr>
                ),
              )}
            </Tbody>
          </Table>

          <OrderDetail
            isOpen={isOpen}
            onClose={handleCloseDetailDrawer}
            selectedOrder={filteredOrders.find(({ id }) => id === selectedOrder) || null}
          />
        </>
      ) : (
        <Center h={16}>
          <Text>暫無訂單</Text>
        </Center>
      )}
    </Box>
  );
};

export default OperationTable;
