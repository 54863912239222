import React from 'react';
import {
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  HStack,
  Button,
  VStack,
} from '@chakra-ui/react';

import { ButtonVariants } from '~/customTheme/components/Button';

type CustomDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  title: string;
  customButton?: React.ReactNode;
};

const CustomDrawer: React.FC<CustomDrawerProps> = ({
  isOpen,
  onClose,
  onSave,
  title,
  children,
  customButton,
}) => {
  return (
    <Drawer size='md' isOpen={isOpen} placement='right' onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent position='relative'>
        <DrawerCloseButton
          mt={1.5}
          border='2px solid'
          borderRadius='full'
          borderColor='#D4D4D4'
          color='#D4D4D4'
          fontSize='sm'
          _focus={{ boxShadow: 'none' }}
        />
        <DrawerHeader
          borderBottomWidth='1px'
          borderBottomColor='admin.gray.300'
          fontSize='lg'
          fontWeight='bold'
          p={{ base: 3, sm: 4 }}
        >
          {title}
        </DrawerHeader>
        <DrawerBody p={{ base: 0, sm: 4 }}>
          <VStack
            px='0.8rem'
            py='0.8rem'
            overflowY='auto'
            justify='space-between'
            spacing={6}
            align='stretch'
          >
            {children}
          </VStack>
        </DrawerBody>
        <DrawerFooter
          borderTopWidth='1px'
          borderTopColor='admin.gray.300'
          px={{ base: 0, sm: 4 }}
          flexDirection='column'
        >
          {customButton}
          <HStack
            bg='white'
            align='stretch'
            justify='space-between'
            spacing={5}
            px='0.8rem'
            w='100%'
          >
            <Button
              variant={ButtonVariants.MODAL_DANGER}
              onClick={onClose}
              w='13rem'
            >
              取消
            </Button>
            <Button w='13rem' variant={ButtonVariants.SOLID} onClick={onSave}>
              儲存
            </Button>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default CustomDrawer;
