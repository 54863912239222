import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { firebaseAuth } from '~/firebase/initialize';

import { authUserAtom, loginInitAtom } from '~/recoil/atom';

export default function useAuth() {
  const setAuthUser = useSetRecoilState(authUserAtom);
  const setLoginInit = useSetRecoilState(loginInitAtom);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged((newAuthUser) => {
      setLoginInit(true);
      setAuthUser(newAuthUser);
    });
  }, []);
}
