import type { FC } from 'react';

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  Text,
  Divider,
  HStack,
  Box,
  Collapse,
} from '@chakra-ui/react';

import OrderDetailField from './OrderDetailField';
import CancelButton from './CancelButton';
import AbandonButton from './AbandonButton';
import CompleteButton from './CompleteButton';
import OrderDetailItems from './OrderDetailItems';

import { OrderDisplayObj, TaxType, PaymentType, WsOrderStatusType } from '../type';

import { statusConvert } from '../utils';
import OrderDetailItemField from './OrderDetailItemField';

type OrderDetailProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedOrder: OrderDisplayObj | null;
};

const paymentTypeConvert: { [key in PaymentType]: string } = {
  [PaymentType.CASH]: '取貨後付現',
  [PaymentType.CREDIT]: '線上支付',
  [PaymentType.LINEPAY]: 'LINEPAY',
};

const OrderDetail: FC<OrderDetailProps> = ({ onClose, isOpen, selectedOrder }) => {
  if (!selectedOrder) {
    return <></>;
  }
  const {
    id,
    orderNumber,
    allowPaymentNotRefund,
    status,
    customer,
    createdAt,
    preOrderAt,
    total,
    paymentType,
    paymentInfo,
    reasonForCancelling,
    invoice,
    invoiceEmail,
    taxType,
    taxInfo,
    orders,
  } = selectedOrder;
  return (
    <Drawer size="sm" placement="right" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton
          m={3}
          size="sm"
          color="admin.gray.600"
          borderWidth={1}
          borderRadius={50}
          borderColor="admin.gray.600"
        />
        <DrawerHeader borderBottomWidth="1px">訂單詳細</DrawerHeader>
        <DrawerBody>
          <VStack spacing={4} align="stretch">
            <OrderDetailField title="訂單編號" render={<Text fontSize="sm">{orderNumber}</Text>} />

            <OrderDetailField
              title="客戶資訊"
              isMultiple
              render={
                <VStack spacing={4} align="stretch">
                  <Text fontSize="sm" flex="1">
                    {customer.displayName}
                  </Text>
                  <Text fontSize="sm">{customer.phoneNumber}</Text>
                </VStack>
              }
            />

            <OrderDetailField title="下單時間" render={<Text fontSize="sm">{createdAt}</Text>} />

            <OrderDetailField
              title="預計取餐時間"
              render={<Text fontSize="sm">{preOrderAt}</Text>}
            />

            <OrderDetailField
              title="發票號碼"
              render={<Text fontSize="sm">{invoice?.invoiceNumber || '-'}</Text>}
            />

            <OrderDetailField
              title="發票信箱"
              render={
                <Text fontSize="sm" flex="1">
                  {invoiceEmail}
                </Text>
              }
            />

            {taxType === TaxType.COMPANY && (
              <>
                <OrderDetailField
                  title="公司抬頭"
                  render={
                    <Text fontSize="sm" flex="1">
                      {taxInfo.title}
                    </Text>
                  }
                />
                <OrderDetailField
                  title="統編"
                  render={<Text fontSize="sm">{taxInfo.invoice}</Text>}
                />
              </>
            )}

            <OrderDetailField
              title="店家端狀態"
              render={
                <HStack justifyContent="space-between" flex={1}>
                  <Text fontSize="sm">{statusConvert({ status, allowPaymentNotRefund })}</Text>
                  {status === WsOrderStatusType.ACCEPTED && <CompleteButton orderId={id} />}
                </HStack>
              }
            />

            {(reasonForCancelling?.length || 0) > 0 && (
              <OrderDetailField
                title="取消原因"
                render={<Text fontSize="sm">{reasonForCancelling?.join('、')}</Text>}
              />
            )}

            <OrderDetailField
              title="付款方式"
              isMultiple
              render={
                <VStack spacing={4} align="stretch">
                  <Text fontSize="sm">{paymentTypeConvert[paymentType]}</Text>
                  {status === WsOrderStatusType.ACCEPTED && (
                    <>
                      <CancelButton method={paymentType} orderId={id} />
                      <AbandonButton orderId={id} />
                    </>
                  )}
                </VStack>
              }
            />

            <OrderDetailField
              title="金流序號"
              render={
                <Text fontSize="sm" flex="1" wordBreak="break-all">
                  {paymentInfo.gatewayTransactionId || '-'}
                </Text>
              }
            />

            <OrderDetailField
              title="訂單金額"
              render={
                <Text fontSize="sm" flex="1">
                  {total}
                </Text>
              }
            />
            <Divider />

            {orders.map(({ name, total, items }) => (
              <>
                <OrderDetailField
                  title="店家"
                  render={
                    <Text fontSize="sm" flex="1">
                      {name}
                    </Text>
                  }
                />
                <OrderDetailField
                  title="金額"
                  render={
                    <Text fontSize="sm" flex="1">
                      NT${total}
                    </Text>
                  }
                />

                <OrderDetailItems items={items} />
              </>
            ))}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default OrderDetail;
