import React from 'react';
import { useRecoilState } from 'recoil';
import { Tabs, Tab, TabList } from '@chakra-ui/react';

import { selectedStoreIdAtom } from '~/recoil/atom';

const StoresTab = ({ storeList }: { storeList: { id: string; name: string }[] }) => {
  const [selectedStoreId, setSelectedStoreId] = useRecoilState(selectedStoreIdAtom);
  return (
    <Tabs
      variant="button"
      bg="white"
      py={4}
      index={storeList.findIndex(({ id }) => id === selectedStoreId)}
      onChange={(index) => setSelectedStoreId(storeList[index].id)}
    >
      <TabList>
        {storeList.map(({ id, name }) => (
          <Tab value={id}>{name}</Tab>
        ))}
      </TabList>
    </Tabs>
  );
};

export default StoresTab;
