import { Text, useDisclosure, VStack, useToast } from '@chakra-ui/react';
import { ConfirmModal } from '~/components/UI/Modal';
import { abandonOrder } from '~/firebase/order';

const AbandonButton = ({ orderId }: { orderId: string }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const handleConfirm = async () => {
    try {
      await abandonOrder({ orderId });
      toast({
        title: '訂單完成',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    } catch (error) {
      console.log(error);
      toast({
        title: '伺服器錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    }
    onClose();
  };

  return (
    <>
      <Text
        color="admin.red.700"
        fontSize="sm"
        cursor="pointer"
        _hover={{ textDecoration: 'underline' }}
        onClick={onOpen}
      >
        客戶棄單
      </Text>
      <ConfirmModal
        content={
          <VStack spacing={6} mb={6}>
            <Text mx={5} textAlign="center" fontSize="lg" fontWeight="bold">
              確定客戶棄單？
            </Text>
            <Text textAlign="center">客戶棄單僅供取消訂單，無退款動作</Text>
          </VStack>
        }
        isOpen={isOpen}
        onConfirm={handleConfirm}
        onClose={onClose}
      />
    </>
  );
};

export default AbandonButton;
