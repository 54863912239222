import React from 'react';
import { Center } from '@chakra-ui/react';

import baseStyle from '../styles/baseStyle';

type NormalDayProps = {
  day: number;
};

const NormalDay: React.FC<NormalDayProps> = ({ day }) => {
  return (
    <Center {...baseStyle} cursor="pointer" _hover={{ border: '1px solid', borderRadius: 'full' }}>
      {day}
    </Center>
  );
};

export default NormalDay;
