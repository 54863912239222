import { OrderDisplayObj } from '~/components/TodayOrders/type';
import { FilterReduceType, FilterOrder } from './statusReducer';

export const orderFilter = (orders: OrderDisplayObj[], filter: FilterReduceType) => {
  let filteredOrders = [...orders];
  const { search, order: filterOrder } = filter;

  // search customer phoneNumber
  if (search !== '') {
    filteredOrders = filteredOrders.filter(
      ({ customer, orderNumber }) =>
        customer.phoneNumber.includes(search) || orderNumber.includes(search),
    );
  }

  // sort orders by created time (createdAt is string and 00:00 < 00:01 is true)
  filterOrder.createdAt === FilterOrder.HIGH &&
    filteredOrders.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
  filterOrder.createdAt === FilterOrder.LOW &&
    filteredOrders.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));

  // sort orders by total
  filterOrder.preOrderAt === FilterOrder.HIGH &&
    filteredOrders.sort((a, b) => (a.preOrderAt < b.preOrderAt ? 1 : -1));
  filterOrder.preOrderAt === FilterOrder.LOW &&
    filteredOrders.sort((a, b) => (a.preOrderAt > b.preOrderAt ? 1 : -1));

  return { filteredOrders };
};
