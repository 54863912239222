import { selector, selectorFamily } from 'recoil';
import { MenuWithId, Menu } from '~/types/store';

import { storeAtom } from '../atom';

const menusSelector = selector<Record<string, Menu>>({
  key: 'menus',
  get: ({ get }) => {
    const store = get(storeAtom);
    if (!store) return {};
    return store.menus;
  },
});

const singleMenuSelector = selectorFamily<MenuWithId | null, string>({
  key: 'menu',
  get:
    (targetId) =>
    ({ get }) => {
      const store = get(storeAtom);
      if (!store) return null;
      return store.menus[targetId] ? { ...store.menus[targetId], id: targetId } : null;
    },
});

export { menusSelector, singleMenuSelector };
