import { FC } from 'react';
import { Link as ReachLink, useParams } from 'react-router-dom';

import {
  VStack,
  Center,
  Heading,
  Link,
  HStack,
  Button,
  Icon,
  useMediaQuery,
} from '@chakra-ui/react';

import { ButtonVariants } from '~/customTheme/components/Button';

import { AiOutlineLeft } from 'react-icons/ai';

type TitleOperationProps = {
  title: string;
  backButton?: boolean;
  isEditable?: string;
  buttonTitle?: string;
  onBtnClick?: () => void;
};

const TitleOperation: FC<TitleOperationProps> = ({
  title,
  backButton = false,
  isEditable,
  buttonTitle,
  onBtnClick,
}) => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const params = useParams();
  const { menuId, categoryId, itemId } = params;
  let backPath = '/menu';
  backPath += categoryId ? `/${menuId}` : '';
  backPath += itemId ? `/${categoryId}` : '';
  return (
    <>
      <VStack justify="space-between" bg="white" spacing={3} align="stretch">
        {isLargerThan768 && (
          <HStack>
            <Center display={backButton ? 'block' : 'none'}>
              <Icon as={AiOutlineLeft} boxSize={3} color="admin.gray.600" />
              <Link as={ReachLink} color="admin.gray.600" to={backPath}>
                回上一頁
              </Link>
            </Center>
          </HStack>
        )}
        <HStack justify="space-between" display="flex" bg="white">
          <Heading color="admin.black.400" fontSize="2xl" fontWeight="bold" letterSpacing="0.1rem">
            {title}
          </Heading>
          <Button
            variant={ButtonVariants.SOLID}
            iconSpacing={1}
            fontSize="md"
            fontWeight="normal"
            borderRadius="5px"
            display={isEditable}
            onClick={onBtnClick}
            minW="7rem"
          >
            {buttonTitle}
          </Button>
        </HStack>
      </VStack>
    </>
  );
};

export default TitleOperation;
