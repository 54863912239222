import { ComponentStyleConfig } from '@chakra-ui/react';

const Textarea: ComponentStyleConfig = {
  baseStyle: {
    color: 'admin.black.400',
  },
  defaultProps: {
    focusBorderColor: 'admin.black.700',
  },
};

export default Textarea;
