import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Text, Box, Input, useToast } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';

import CustomDrawer from '~/components/UI/Drawer';
import { selectedStoreIdAtom } from '~/recoil/atom';
import { EditCategory } from './types';

import { editCategory } from '~/firebase/store';

type EditCategoryProps = {
  isOpen: boolean;
  onClose: () => void;
  name: string;
};

const EditCategoryDrawer: React.FC<EditCategoryProps> = ({ isOpen, onClose, name }) => {
  const { control, handleSubmit, reset } = useForm<EditCategory>({
    defaultValues: { name },
  });

  useEffect(() => {
    isOpen && reset({ name });
  }, [isOpen]);

  const validateNoSpace = (name: string) => {
    return name.trim().length !== 0;
  };

  const toast = useToast();
  const storeId = useRecoilValue(selectedStoreIdAtom);
  const params = useParams();
  const { categoryId = '' } = params;

  const handleSaveNewCategory = async (data: EditCategory) => {
    try {
      await editCategory({ storeId, categoryId, newCategoryName: data.name });
      toast({
        position: 'top',
        title: '修改成功',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.log(error);
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <CustomDrawer
      title="類別名稱"
      isOpen={isOpen}
      onClose={onClose}
      onSave={handleSubmit(handleSaveNewCategory)}
    >
      <Box as="label" justifyContent="space-between">
        <Text mb="0.2rem" htmlFor="name" color="#8F8F8F" fontSize="md">
          類別名稱
        </Text>
        <Controller
          control={control}
          name="name"
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <Input {...field} isInvalid={!!error} errorBorderColor="admin.error" />
          )}
          rules={{ required: true, validate: (name) => validateNoSpace(name) }}
        />
      </Box>
    </CustomDrawer>
  );
};

export default EditCategoryDrawer;
