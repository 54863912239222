import { selectorFamily, selector } from 'recoil';
import { CategoryWithId, Category } from '~/types/store';
import pickBy from 'lodash/pickBy';
import { storeAtom, selectedMenuIdAtom } from '../atom';

const categoriesSelector = selector<Record<string, Category>>({
  key: 'categories',
  get: ({ get }) => {
    const store = get(storeAtom);
    const menuId = get(selectedMenuIdAtom);
    if (!store || !menuId) return {};
    const targetCode = store.menus[menuId].code;
    return pickBy(store.categories, (category) => category.code === targetCode);
  },
});

const singleCategorySelector = selectorFamily<CategoryWithId | null, string>({
  key: 'category',
  get:
    (targetId) =>
    ({ get }) => {
      const store = get(storeAtom);
      if (!store) return null;
      return store.categories[targetId] ? { ...store.categories[targetId], id: targetId } : null;
    },
});

export { categoriesSelector, singleCategorySelector };
