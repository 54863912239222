import { useReducer } from 'react';

/* -------------------- filter reducer status enum & type ------------------- */

export enum FilterType {
  ALL = 'ALL',
  PARTNER_DELIVERY = 'PARTNER_DELIVERY',
  SELF_DELIVERY = 'SELF_DELIVERY',
  PICKUP = 'PICKUP',
}

export enum FilterStatus {
  ALL = 'ALL',
  CREATED = 'CREATED',
  ACCEPTED = 'ACCEPTED',
  PICKED = 'PICKED',
  COMPLETE = 'COMPLETE',
  CANCELLED_AND_FAILED = 'CANCELLED_AND_FAILED',
}

export enum FilterOrder {
  HIGH = 'HIGH',
  LOW = 'LOW',
  NONE = 'NONE',
}

export enum FilterAction {
  SEARCH = 'SEARCH',
  TYPE = 'TYPE',
  STATUS = 'STATUS',
  ORDER_PREORDER_AT = 'ORDER_PREORDER_AT',
  ORDER_CREATED_AT = 'ORDER_CREATED_AT',
  ORDER_TOTAL = 'ORDER_TOTAL',
}

export type FilterReduceType = {
  search: string;
  type: FilterType;
  status: FilterStatus;
  order: {
    preOrderAt: FilterOrder;
    createdAt: FilterOrder;
    total: FilterOrder;
  };
};

export type FilterActionType =
  | {
      type: FilterAction.SEARCH;
      payload: string;
    }
  | {
      type: FilterAction.STATUS;
      payload: FilterStatus;
    }
  | {
      type: FilterAction.TYPE;
      payload: FilterType;
    }
  | {
      type: FilterAction.ORDER_PREORDER_AT;
    }
  | {
      type: FilterAction.ORDER_CREATED_AT;
    }
  | {
      type: FilterAction.ORDER_TOTAL;
    };

/* -------------------- filter reducer status enum & type ------------------- */

const changeOrder = (prev: FilterOrder): FilterOrder => {
  switch (prev) {
    case FilterOrder.NONE:
      return FilterOrder.HIGH;
    case FilterOrder.HIGH:
      return FilterOrder.LOW;
    case FilterOrder.LOW:
      return FilterOrder.HIGH;
  }
};

const filterReducer = (state: FilterReduceType, action: FilterActionType): FilterReduceType => {
  switch (action.type) {
    case FilterAction.SEARCH:
      return { ...state, search: action.payload };
    case FilterAction.TYPE:
      return { ...state, type: action.payload };
    case FilterAction.STATUS:
      return { ...state, status: action.payload };
    case FilterAction.ORDER_CREATED_AT:
      return {
        ...state,
        order: {
          preOrderAt: FilterOrder.NONE,
          total: FilterOrder.NONE,
          createdAt: changeOrder(state.order.createdAt),
        },
      };
    case FilterAction.ORDER_PREORDER_AT:
      return {
        ...state,
        order: {
          preOrderAt: changeOrder(state.order.preOrderAt),
          total: FilterOrder.NONE,
          createdAt: FilterOrder.NONE,
        },
      };
    case FilterAction.ORDER_TOTAL:
      return {
        ...state,
        order: {
          preOrderAt: FilterOrder.NONE,
          total: changeOrder(state.order.total),
          createdAt: FilterOrder.NONE,
        },
      };
  }
};

const initialFilterState: FilterReduceType = {
  search: '',
  type: FilterType.ALL,
  status: FilterStatus.ALL,
  order: {
    preOrderAt: FilterOrder.NONE,
    createdAt: FilterOrder.HIGH,
    total: FilterOrder.NONE,
  },
};

export const useFilterReducer = () => {
  const [filterState, filterDispatch] = useReducer(filterReducer, initialFilterState);

  return { filterState, filterDispatch };
};
