import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, useMediaQuery, Text } from '@chakra-ui/react';

import Menu from './Menu';
import Header from './Header';
import GoTop from './GoTop';

const PageContainer = () => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  return isLargerThan768 ? (
    <Box >
      <Box
        w={72}
        position="fixed"
        top={0}
        bottom={-3}
        overflowY="scroll"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Menu />
      </Box>

      <Box pl={72} bg="admin.gray.100">
        <Header />
        <Outlet />
        <Text
          textAlign="center"
          fontSize="xs"
          color="admin.gray.700"
          py={4}
        >{`Copyright © ${new Date().getFullYear()} weserve. All rights reserved.`}</Text>
      </Box>
      <GoTop />
    </Box>
  ) : (
    <Box>
      <Header />
      <Outlet />
      <Text
        textAlign="center"
        fontSize="xs"
        color="admin.gray.700"
        py={4}
      >{`Copyright © ${new Date().getFullYear()} weserve. All rights reserved.`}</Text>
    </Box>
  );
};

export default PageContainer;
