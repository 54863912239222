import { firebaseFirestore } from './initialize';
import { WsOrderStatusType } from '~/components/TodayOrders/type';

export const cancelOrder = ({
  orderId,
  reasonForCancelling,
}: {
  orderId: string;
  reasonForCancelling: string[];
}) => {
  return firebaseFirestore.doc(`orders/${orderId}`).update({
    status: WsOrderStatusType.FAILED,
    reasonForCancelling,
  });
};

export const abandonOrder = ({ orderId }: { orderId: string }) => {
  return firebaseFirestore.doc(`orders/${orderId}`).update({
    status: WsOrderStatusType.FAILED,
    allowPaymentNotRefund: true,
  });
};

export const completeOrder = ({ orderId }: { orderId: string }) => {
  return firebaseFirestore.doc(`orders/${orderId}`).update({
    status: WsOrderStatusType.COMPLETED,
  });
};
