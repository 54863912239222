import { useRoutes, Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import PageContainer from '~/components/UI/PageContainer';

import AuthProvider from '~/provider/AuthProvider';
import StoreListProvider from '~/provider/StoreListProvider';
import SelectedStoreProvider from '~/provider/SelectedStoreProvider';
import Login from '~/pages/Login';
import TodayOrders from '~/pages/TodayOrders';
import OperatingReport from '~/pages/OperatingReport';
import MenuList from '~/pages/MenuList';
import CategoryList from '~/pages/CategoryList';
import ItemList from '~/pages/ItemList';
import ModifierList from '~/pages/ModifierList';

const routes: Array<RouteObject> = [
  {
    path: '/',
    element: (
      <AuthProvider>
        <StoreListProvider>
          <SelectedStoreProvider />
        </StoreListProvider>
      </AuthProvider>
    ),
    children: [
      {
        path: '/',
        element: <PageContainer />,
        children: [
          {
            path: '/',
            element: <TodayOrders />,
          },
          {
            path: '/operatingReport',
            element: <OperatingReport />,
          },
          { path: '/menu', element: <MenuList /> },
          { path: '/menu/:menuId', element: <CategoryList /> },
          { path: '/menu/:menuId/:categoryId', element: <ItemList /> },
          { path: '/menu/:menuId/:categoryId/:itemId', element: <ModifierList /> },
        ],
      },
      {
        path: '/login',
        element: <Login />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
];

const RouterContainer = () => {
  const element = useRoutes(routes);
  return element;
};

export default RouterContainer;
