import type { ComponentStyleConfig } from '@chakra-ui/react';

export enum ButtonVariants {
  TEXT = 'text',
  SOLID = 'solid',
  OUTLINE = 'outline',
  DANGER_OUTLINE = 'dangerOutline',
  MODAL_PRIMARY = 'modalPrimary',
  MODAL_DANGER = 'modalDanger',
}

const Button: ComponentStyleConfig = {
  variants: {
    [ButtonVariants.TEXT]: {
      bg: 'transparent',
      color: 'admin.black.400',
      w: 'auto',
      minW: 'auto',
      h: 'auto',
      p: 0,
      fontWeight: 'normal',
      _hover: {
        bg: 'transparent',
        color: 'admin.black.400',
      },
      _focus: {
        boxShadow: 'none',
      },
      _active: {
        bg: 'transparent',
        color: 'admin.black.400',
      },
    },
    [ButtonVariants.SOLID]: {
      bg: 'admin.primary',
      color: 'white',
      borderRadius: 'sm',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        bg: 'admin.black.400',
      },
    },
    [ButtonVariants.OUTLINE]: {
      bg: 'transparent',
      color: 'admin.primary',
      borderColor: 'admin.primary',
      borderRadius: 'sm',
      w: 24,
      h: 9,
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        bg: 'admin.red.100',
      },
    },
    [ButtonVariants.DANGER_OUTLINE]: {
      bg: 'transparent',
      color: 'admin.error',
      border: '1px solid',
      borderColor: 'admin.error',
      borderRadius: 'sm',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        bg: 'admin.red.100',
      },
    },
    [ButtonVariants.MODAL_PRIMARY]: {
      bg: 'admin.gray.50',
      color: 'admin.black.400',
      borderRadius: 'sm',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        bg: 'admin.gray.400',
      },
    },
    [ButtonVariants.MODAL_DANGER]: {
      bg: 'admin.red.100',
      color: 'admin.error',
      borderRadius: 'sm',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        bg: 'admin.primary',
        color: 'white',
      },
    },
  },
};

export default Button;
