import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Center,
  Heading,
  Flex,
  Box,
  VStack,
  Text,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
  SlideFade,
  Checkbox,
  Button,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';

import {
  AiOutlineUser,
  AiOutlineUnlock,
  AiOutlineEyeInvisible,
  AiOutlineEye,
} from 'react-icons/ai';
import Logo from '~/assets/images/globalMallIcon.png';

import { ConfirmModal } from '~/components/UI/Modal';
import { ButtonVariants } from '~/customTheme/components/Button';

import { loginAsync, sendResetPasswordEMail } from '~/firebase/user';

enum FirebaseErrorCode {
  AUTH_USER_NOT_FOUND = 'auth/user-not-found',
  AUTH_WRONG_PASSWORD = 'auth/wrong-password',
}

type LoginData = {
  email: string;
  password: string;
};

const emailError: { [key: string]: string } = {
  NONE: '',
  required: '請輸入帳號',
  userNotFound: '無此使用者',
};

const passwordError: { [key: string]: string } = {
  NONE: '',
  required: '請輸入密碼',
  wrongPassword: '密碼錯誤',
};

const Login = () => {
  const [isPasswordReadable, setIsPasswordReadable] = useState<boolean>(false);
  const [isRememberMe, setIsRememberMe] = useState<boolean>(true);
  const [resetMailText, setResetMailText] = useState<string>('已傳送重置密碼到電子信箱');
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { handleSubmit, control, setError, getValues } = useForm<LoginData>();

  const onSubmit = async (data: LoginData) => {
    try {
      await loginAsync({ ...data, isRememberMe });
      navigate('/');
    } catch (error: any) {
      if (error.code === FirebaseErrorCode.AUTH_USER_NOT_FOUND) {
        setError('email', { type: 'userNotFound' });
      }
      if (error.code === FirebaseErrorCode.AUTH_WRONG_PASSWORD) {
        setError('password', { type: 'wrongPassword' });
      }
      return;
    }
  };

  const handleIsPasswordReadable = () => {
    setIsPasswordReadable((prev) => !prev);
  };

  const handleSetIsRememberMe = (e: any) => {
    setIsRememberMe(e.target.isChecked);
  };

  const handleShowConfirmForgetPasswordModal = async () => {
    const email = getValues('email');
    try {
      await sendResetPasswordEMail(email);
      setResetMailText('已傳送重置密碼到電子信箱');
    } catch (error: any) {
      error.code === FirebaseErrorCode.AUTH_USER_NOT_FOUND
        ? setResetMailText('查無此帳號')
        : setResetMailText('發生錯誤');
    }
    onOpen();
  };

  const handleConfirmForgetPassword = () => {
    onClose();
  };

  const passwordEyeIcon = isPasswordReadable ? (
    <AiOutlineEyeInvisible color="gray.300" />
  ) : (
    <AiOutlineEye color="admin.primary" />
  );

  const passwordType = isPasswordReadable ? 'text' : 'password';

  return (
    <>
      <Center w="100vw" h="100vh">
        <Flex direction="column" align="center" w="330px">
          <Image src={Logo} w={{ base: '250px', sm: '330px' }} />
          <Heading mt="26px" mb="43px" fontSize="4xl" letterSpacing="0.1rem">
            店家管理系統
          </Heading>
          <Box as="form" w="full" bg="white" onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={6} align="stretch" p={6}>
              <Text color="#262626" textAlign="center" fontSize="20px" lineHeight="28px">
                登入你的帳號
              </Text>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <InputGroup position="relative">
                    <InputLeftElement
                      pointerEvents="none"
                      children={<AiOutlineUser color="gray.300" />}
                    />
                    <Input
                      {...field}
                      type="email"
                      placeholder="帳號"
                      isInvalid={!!error}
                      errorBorderColor="admin.error"
                    />
                    <SlideFade
                      in={!!error}
                      offsetY="10px"
                      style={{ position: 'absolute', top: '40px' }}
                    >
                      <Text fontSize="xs" color="admin.error">
                        {emailError[error?.type || 'NONE']}
                      </Text>
                    </SlideFade>
                  </InputGroup>
                )}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <InputGroup position="relative">
                    <InputLeftElement
                      pointerEvents="none"
                      children={<AiOutlineUnlock color="gray.300" />}
                    />
                    <InputRightElement
                      children={passwordEyeIcon}
                      cursor="pointer"
                      onClick={handleIsPasswordReadable}
                    />
                    <Input
                      {...field}
                      type={passwordType}
                      placeholder="密碼"
                      isInvalid={!!error}
                      errorBorderColor="admin.error"
                    />
                    <SlideFade
                      in={!!error}
                      offsetY="10px"
                      style={{ position: 'absolute', top: '40px' }}
                    >
                      <Text fontSize="xs" color="admin.error">
                        {passwordError[error?.type || 'NONE']}
                      </Text>
                    </SlideFade>
                  </InputGroup>
                )}
              />
              <Flex justify="space-between" align="center">
                <Checkbox isChecked={isRememberMe} onChange={handleSetIsRememberMe}>
                  <Text color="#595959" fontSize="14px">
                    記得我的帳號
                  </Text>
                </Checkbox>
                <Button
                  variant={ButtonVariants.TEXT}
                  color="admin.primary"
                  fontSize="14px"
                  fontWeight="normal"
                  lineHeight="24px"
                  onClick={handleShowConfirmForgetPasswordModal}
                >
                  忘記密碼
                </Button>
              </Flex>
              <Button
                type="submit"
                variant={ButtonVariants.SOLID}
                w="full"
                bg="admin.primary"
                borderRadius="base"
              >
                登入
              </Button>
            </VStack>
          </Box>
        </Flex>
      </Center>
      <ConfirmModal
        isOpen={isOpen}
        content={
          <Text pb={7} textAlign="center" fontSize="xl" fontWeight="bold" lineHeight="24px">
            {resetMailText}
          </Text>
        }
        isOnlyConfirm
        modalBodyStyles={{ pt: 7, pb: 5, px: '30px' }}
        onConfirm={handleConfirmForgetPassword}
        onClose={onClose}
      />
    </>
  );
};

export default Login;
