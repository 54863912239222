import React, { useState } from 'react';
import {
  VStack,
  Box,
  Button,
  Icon,
  Center,
  Text,
  HStack,
  useToast,
  useMediaQuery,
  useDisclosure,
} from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';

import { StoresTab, StoresSelect } from '~/components/TodayOrders';
import TitleOperation from '~/components/TitleOperation';

import DatePicker from '~/components/DatePicker';
import { CalendarDate } from '~/components/DatePicker/type';

import { AiOutlineDownload } from 'react-icons/ai';
import { Today } from '~/icons';

import { authUserAtom, storeListAtom, selectedStoreIdAtom } from '~/recoil/atom';

import { format, startOfDay, parse, isAfter, endOfDay, formatISO, isBefore } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

const OperatingReport = () => {
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const storeList = useRecoilValue(storeListAtom);
  const selectedStoreId = useRecoilValue(selectedStoreIdAtom);

  const [rangeDate, setRangeDate] = useState<CalendarDate>({
    start: format(startOfDay(new Date()), 'yyyy/MM/dd'),
    end: null,
  });

  const [rangeEndDate, setRangeEndDate] = useState<CalendarDate>({
    start: format(endOfDay(new Date()), 'yyyy/MM/dd'),
    end: null,
  });

  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isEndDateOpen, onClose: onEndDateClose, onOpen: onEndDateOpen } = useDisclosure();

  const auth = useRecoilValue(authUserAtom);

  const toast = useToast();

  //選擇開始日期
  const handleOpenSelectDate = () => {
    toast({
      title: '已修改選擇日期',
      status: 'success',
      duration: 1000,
      isClosable: true,
      position: 'top',
    });
    setRangeEndDate({ start: format(endOfDay(new Date()), 'yyyy/MM/dd'), end: null });
    onClose();
  };

  //選擇結束日期
  const handleOpenSelectEndDate = () => {
    toast({
      title: '已修改選擇日期',
      status: 'success',
      duration: 1000,
      isClosable: true,
      position: 'top',
    });
    onEndDateClose();
  };

  const handleOpenDeskTopStart = () => {
    onOpen();
    onEndDateClose();
  };

  const handleOpenDeskTopEnd = () => {
    onEndDateOpen();
    onClose();
  };

  //按下營運報表下載之後直接下載excel
  const handleDownload = async (start: Date, end: Date) => {
    if (!auth) {
      return;
    }

    const token = await auth.getIdToken();
    const utcStartDate = zonedTimeToUtc(formatISO(start), 'Asia/Taipei').toISOString();
    const utcEndDate = zonedTimeToUtc(formatISO(end), 'Asia/Taipei').toISOString();

    const report_url = process.env.REACT_APP_GET_REDIRECTION_REPORT_URL;

    window.open(
      `${report_url}/${selectedStoreId}?start=${utcStartDate}&end=${utcEndDate}&token=${token}`,
    );

    return null;
  };

  return (
    <VStack spacing={4} align="stretch" mb={4}>
      <Box mb="15px" bg="white" pb={4} px={5}>
        <TitleOperation title="過去訂單" isEditable="none" />
      </Box>

      <VStack px={5} align="stretch" spacing={4}>
        {isLargerThan1024 ? (
          <HStack bg="white" p={8} spacing={3}>
            <Text>開始日期</Text>
            <HStack
              py={1}
              px={2}
              spacing={8}
              borderRadius="5px"
              align="center"
              border="1px solid #A4A4A4"
              justify="space-between"
              onClick={handleOpenDeskTopStart}
              cursor="pointer"
            >
              <Text fontSize="sm" color="admin.gray.600" letterSpacing={0.6} textAlign="center">
                {format(parse(rangeDate.start, 'yyyy/MM/dd', new Date()), 'yyyy-MM-dd')}
              </Text>
              <Icon as={Today} boxSize="1.125rem" color="admin.gray.600" />
            </HStack>

            <Text color="admin.gray.600">-</Text>
            <Text>結束日期</Text>
            <HStack
              py={1}
              px={2}
              spacing={8}
              borderRadius="5px"
              align="center"
              border="1px solid #A4A4A4"
              justify="space-between"
              onClick={handleOpenDeskTopEnd}
              cursor="pointer"
            >
              <Text fontSize="sm" color="admin.gray.600" letterSpacing={0.6} textAlign="center">
                {format(parse(rangeEndDate.start, 'yyyy/MM/dd', new Date()), 'yyyy-MM-dd')}
              </Text>
              <Icon as={Today} boxSize="1.125rem" color="admin.gray.600" />
            </HStack>
          </HStack>
        ) : (
          <VStack bg="white" p={5} spacing={5} align="stretch">
            <HStack>
              <Text maxW="10rem">開始日期</Text>
              <HStack
                flex="1"
                py={1}
                px={2}
                borderRadius="5px"
                align="center"
                border="1px solid #A4A4A4"
                justify="space-between"
                onClick={onOpen}
                cursor="pointer"
              >
                <Text fontSize="sm" color="admin.gray.600" letterSpacing={0.6} textAlign="center">
                  {format(parse(rangeDate.start, 'yyyy/MM/dd', new Date()), 'yyyy-MM-dd')}
                </Text>
                <Icon as={Today} boxSize="1.125rem" color="admin.gray.600" />
              </HStack>
            </HStack>

            <HStack>
              <Text>結束日期</Text>
              <HStack
                flex="1"
                py={1}
                px={2}
                spacing={8}
                borderRadius="5px"
                align="center"
                border="1px solid #A4A4A4"
                justify="space-between"
                onClick={onEndDateOpen}
                cursor="pointer"
              >
                <Text fontSize="sm" color="admin.gray.600" letterSpacing={0.6} textAlign="center">
                  {format(parse(rangeEndDate.start, 'yyyy/MM/dd', new Date()), 'yyyy-MM-dd')}
                </Text>
                <Icon as={Today} boxSize="1.125rem" color="admin.gray.600" />
              </HStack>
            </HStack>
          </VStack>
        )}
        <Box>
          <Box pos="relative" zIndex={1000}>
            <DatePicker
              isOpen={isOpen}
              onClose={handleOpenSelectDate}
              rangeDate={rangeDate}
              onSetRangeDate={(rangeDate) => setRangeDate(rangeDate)}
            />
          </Box>
          <Box pos="relative" zIndex={1000}>
            <DatePicker
              isOpen={isEndDateOpen}
              onClose={handleOpenSelectEndDate}
              rangeDate={rangeEndDate}
              onSetRangeDate={(rangeEndDate) => setRangeEndDate(rangeEndDate)}
              selectedStartDate={rangeDate.start}
            />
          </Box>
        </Box>

        {isLargerThan1024 ? (
          <StoresTab storeList={storeList} />
        ) : (
          <StoresSelect storeList={storeList} />
        )}
      </VStack>
      {isLargerThan768 ? (
        <VStack align="stretch" px={4}>
          <Button
            borderRadius="5px"
            letterSpacing={2}
            onClick={() =>
              handleDownload(
                parse(rangeDate.start, 'yyyy/MM/dd', new Date()),
                endOfDay(parse(rangeEndDate.start, 'yyyy/MM/dd', new Date())),
              )
            }
          >
            <Center>
              <Icon as={AiOutlineDownload} mr={2} />
              <Text color="white">營運報表下載</Text>
            </Center>
          </Button>
        </VStack>
      ) : (
        <Button
          position="fixed"
          bottom="0"
          width="full"
          borderRadius="0"
          letterSpacing={2}
          p={7}
          onClick={() =>
            handleDownload(
              parse(rangeDate.start, 'yyyy/MM/dd', new Date()),
              endOfDay(parse(rangeEndDate.start, 'yyyy/MM/dd', new Date())),
            )
          }
        >
          <Center>
            <Icon as={AiOutlineDownload} mr={2} boxSize="1.5rem" />
            <Text color="white" fontSize="xl" fontWeight="bold">
              營運報表下載
            </Text>
          </Center>
        </Button>
      )}
    </VStack>
  );
};

export default OperatingReport;
