import React from 'react';
import { useMediaQuery } from '@chakra-ui/react';

import type { CalendarDate } from './type';
import Desktop from './Desktop';
import Mobile from './Mobile';

type DatePickerProps = {
  isOpen: boolean;
  onClose: () => void;
  rangeDate: CalendarDate;
  onSetRangeDate: (rangeDate: CalendarDate) => void;
  selectedStartDate?:string;
};

const DatePicker: React.FC<DatePickerProps> = ({
  rangeDate,
  onSetRangeDate,
  isOpen,
  onClose,
  selectedStartDate,
}) => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  return isLargerThan768 ? (
    <Desktop
      isOpen={isOpen}
      onSetRangeDate={onSetRangeDate}
      onClose={onClose}
      rangeDate={rangeDate}
      selectedStartDate={selectedStartDate}
    />
  ) : (
    <Mobile
      isOpen={isOpen}
      onSetRangeDate={onSetRangeDate}
      onClose={onClose}
      rangeDate={rangeDate}
      selectedStartDate={selectedStartDate}
    />
  );
};

export default DatePicker;
