import { useState } from 'react';
import { Link as ReachLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Link,
  Center,
  Switch,
  useToast,
  useDisclosure,
  useMediaQuery,
  Button,
} from '@chakra-ui/react';

import { ConfirmModal } from '~/components/UI/Modal';
import Pagination from '~/components/UI/Pagination';
import { selectedStoreIdAtom } from '~/recoil/atom';
import { menusSelector } from '~/recoil/selector/menu';
import { toggleAllowPublishOfMenu, deleteMenu } from '~/firebase/store';

import { ButtonVariants } from '~/customTheme/components/Button';

const MenuListTable = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [readyToDeleteMenu, setReadyToDeleteMenu] = useState<{ id: string; name: string } | null>(
    null,
  );

  const storeId = useRecoilValue(selectedStoreIdAtom);
  const menus = useRecoilValue(menusSelector);

  const orderedMenu = [
    ...Object.entries(menus)
      .map(([id, entry]) => {
        return { id, ...entry };
      })
      .filter(({ allowPublish }) => allowPublish)
      .sort((a, b) => (a.id > b.id ? -1 : 1)),
    ...Object.entries(menus)
      .map(([id, entry]) => {
        return { id, ...entry };
      })
      .filter(({ allowPublish }) => !allowPublish)
      .sort((a, b) => (a.id > b.id ? -1 : 1)),
  ];

  const displayMenu = orderedMenu.slice((currentPage - 1) * 10, currentPage * 10);

  const handleOpenDeleteModal = (id: string, name: string) => {
    setReadyToDeleteMenu({ id, name });
    onOpen();
  };

  const handleToggleAllowPublish = async (menuId: string, menuName: string, value: boolean) => {
    try {
      await toggleAllowPublishOfMenu({ storeId, menuId, value });
      toast({
        position: 'top',
        title: value ? `已開啟${menuName}菜單顯示` : `已關閉${menuName}菜單顯示`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleConfirmDelete = async () => {
    if (readyToDeleteMenu === null) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const menuId = readyToDeleteMenu.id;
    try {
      await deleteMenu({ storeId, menuId });
      toast({
        position: 'top',
        title: `「${readyToDeleteMenu.name}」已刪除`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    setReadyToDeleteMenu(null);
    onClose();
  };

  return (
    <>
      {orderedMenu.length ? (
        <>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr h={10}>
                <Th w="20%" textAlign="center">
                  顯示
                </Th>
                <Th w="55%">專櫃名稱</Th>
                <Th w="25%">操作</Th>
              </Tr>
            </Thead>
            <Tbody bg="white">
              {displayMenu.map((menu) => (
                <Tr key={menu.id} h={14}>
                  <Td w="20%" textAlign="center">
                    <Switch
                      size={isLargerThan768 ? 'lg' : 'md'}
                      isChecked={menu.allowPublish}
                      onChange={() =>
                        handleToggleAllowPublish(menu.id, menu.name, !menu.allowPublish)
                      }
                      colorScheme="tableStatus"
                    />
                  </Td>
                  <Td w="55%">
                    <Link as={ReachLink} to={`/menu/${menu.id}`} wordBreak="break-all">
                      {menu.name}
                    </Link>
                  </Td>
                  <Td w="25%">
                    <Button
                      variant={ButtonVariants.TEXT}
                      fontSize="15px"
                      onClick={() => handleOpenDeleteModal(menu.id, menu.name)}
                    >
                      刪除
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            maximumPageNumber={Math.ceil(orderedMenu.length / 10)}
          />
        </>
      ) : (
        <Center h={16}>
          <Text>暫無專櫃</Text>
        </Center>
      )}
      <ConfirmModal
        isOpen={isOpen}
        content={
          <Text pb={7} textAlign="center" fontSize="xl" fontWeight="bold" lineHeight="24px">
            確定刪除{readyToDeleteMenu?.name || ''}菜單？
          </Text>
        }
        modalBodyStyles={{ pt: 7, pb: 5, px: '30px' }}
        onConfirm={handleConfirmDelete}
        onClose={onClose}
      />
    </>
  );
};

export default MenuListTable;
