import React from 'react';
import { useRecoilState } from 'recoil';
import { Select } from '@chakra-ui/react';

import { selectedStoreIdAtom } from '~/recoil/atom';
const StoresSelect = ({ storeList }: { storeList: { id: string; name: string }[] }) => {
  const [selectedStoreId, setSelectedStoreId] = useRecoilState(selectedStoreIdAtom);
  return (
    <Select
      bg="white"
      borderColor="admin.gray.600"
      value={selectedStoreId}
      onChange={(e) => setSelectedStoreId(e.target.value)}
      color="admin.black.400"
      fontWeight="bold"
      iconColor="admin.gray.600"
    >
      {storeList.map(({ id, name }) => (
        <option value={id}>{name}</option>
      ))}
    </Select>
  );
};

export default StoresSelect;
