import {
  Flex,
  HStack,
  Center,
  Icon,
  Text,
  Portal,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useMediaQuery,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';

import { AiOutlineUser, AiOutlineDown } from 'react-icons/ai';

import MobileMenu from './MobileDrawer';
import { ConfirmModal } from '~/components/UI/Modal';

import { logoutAsync } from '~/firebase/user';

const Content = () => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const handleConfirmLogout = async () => {
    try {
      await logoutAsync();
    } catch (error) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Flex
        justify={isLargerThan768 ? 'flex-end' : 'space-between'}
        align="center"
        h={16}
        w="full"
        px={5}
        bg="white"
      >
        {!isLargerThan768 && <MobileMenu />}

        <Menu>
          <MenuButton>
            <HStack spacing={2}>
              <Center bg="#F2F2F2" boxSize={8} borderRadius="full">
                <Icon as={AiOutlineUser} fontSize="xl" color="admin.black.400" />
              </Center>
              <Icon as={AiOutlineDown} boxSize={3} color="#8C8C8C" />
            </HStack>
          </MenuButton>

          <Portal>
            <MenuList mr={3}>
              <MenuItem h={7} fontSize="sm" onClick={onOpen}>
                登出
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Flex>

      <ConfirmModal
        isOpen={isOpen}
        content={
          <Text pb={7} textAlign="center" fontSize="xl" fontWeight="bold" lineHeight="24px">
            確定登出？
          </Text>
        }
        modalBodyStyles={{ pt: 7, pb: 5, px: '30px' }}
        onConfirm={handleConfirmLogout}
        onClose={onClose}
      />
    </>
  );
};

export default Content;
