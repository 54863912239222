import firebase from 'firebase';

//===========================================
//
//  #####  ##     ##  ##   ##  ###    ###
//  ##     ####   ##  ##   ##  ## #  # ##
//  #####  ##  ## ##  ##   ##  ##  ##  ##
//  ##     ##    ###  ##   ##  ##      ##
//  #####  ##     ##   #####   ##      ##
//
//===========================================

export enum WsOrderStatusType {
  UNPAID = 'WS_STATUS_UNPAID',
  PENDING = 'WS_STATUS_PENDING',
  CREATED = 'WS_STATUS_CREATED',
  CONFIRMED = 'WS_STATUS_CONFIRMED',
  RETRY = 'WS_STATUS_RETRY',
  ACCEPTED = 'WS_STATUS_ACCEPTED',
  PREPARED = 'WS_STATUS_PREPARED',
  PICKED = 'WS_STATUS_PICKED',
  COMPLETED = 'WS_STATUS_COMPLETED',
  FAILED = 'WS_STATUS_FAILED',
  CANCELLED = 'WS_STATUS_CANCELLED',
}

export enum OrderType {
  WS_PICKUP = 'WS_PICKUP',
  WS_DELIVERY = 'WS_DELIVERY',
  WS_PREORDER_PICKUP = 'WS_PREORDER_PICKUP',
  WS_PREORDER_DELIVERY = 'WS_PREORDER_DELIVERY',
}

export enum PaymentType {
  CASH = 'CASH',
  CREDIT = 'CREDIT',
  LINEPAY = 'LINEPAY',
}

export enum TaxType {
  PERSONAL = 'WS_TAX_PERSONAL',
  COMPANY = 'WS_TAX_COMPANY',
}

//===================================================================================================================================================
//
//   #####   #####    ####    #####  #####          ######  ##    ##  #####   #####        #####    #####  ##        ###    ######  #####  ####
//  ##   ##  ##  ##   ##  ##  ##     ##  ##           ##     ##  ##   ##  ##  ##           ##  ##   ##     ##       ## ##     ##    ##     ##  ##
//  ##   ##  #####    ##  ##  #####  #####            ##      ####    #####   #####        #####    #####  ##      ##   ##    ##    #####  ##  ##
//  ##   ##  ##  ##   ##  ##  ##     ##  ##           ##       ##     ##      ##           ##  ##   ##     ##      #######    ##    ##     ##  ##
//   #####   ##   ##  ####    #####  ##   ##          ##       ##     ##      #####        ##   ##  #####  ######  ##   ##    ##    #####  ####
//
//===================================================================================================================================================

export type OrderStore = {
  currency: string;
  id: string;
  location: {
    address: string;
  };
  name: string;
  orderPrepareMinutes: number;
  phoneNumber: string;
  timezone: string;
};

export type OrderOption = {
  id: string;
  name: string;
  price: number;
};

export type OrderModifier = {
  id: string;
  name: string;
  options: Array<OrderOption>;
};

export type OrderItem = {
  count: number;
  id: string;
  name: string;
  note: string;
  price: number;
  tax: number;
  modifiers: Array<OrderModifier>;
};

export type SubOrder = {
  code: string;
  name: string;
  kitchenId: string;
  subtotal: number;
  tax: number;
  total: number;
  items: Array<OrderItem>;
};

export type Order = {
  allowPaymentNotRefund: boolean;
  createdAt: firebase.firestore.Timestamp;
  type: OrderType;
  customer: {
    uid: string;
    displayName: string;
    phoneNumber: string;
  };
  invoiceEmail: string;
  orderNumber: string;
  orders: Array<SubOrder>;
  paymentInfo: {
    isTransactionFailed?: boolean;
    transactionId?: string;
    gatewayTransactionId?: string;
  };
  invoice: {
    invoiceNumber?: string;
  };
  paymentType: PaymentType;
  preOrderAt: firebase.firestore.Timestamp;
  status: WsOrderStatusType;
  statusRecord: Partial<Record<WsOrderStatusType, firebase.firestore.Timestamp>>;
  store: OrderStore;
  subOrderPairs: Record<string, { id: string; status: WsOrderStatusType }>;
  subtotal: number;
  tax: number;
  taxInfo: {
    invoice: string;
    title: string;
  };
  taxType: TaxType;
  total: number;
  reasonForCancelling?: string[];
};

export type OrderDisplayObj = Omit<Order, 'createdAt' | 'preOrderAt'> & {
  id: string;
  createdAt: string;
  preOrderAt: string;
};
