import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getStoreDocs } from '~/firebase/store';

import { authUserAtom, storeListAtom } from '~/recoil/atom';

const storeIdMap: Record<string, { id: string; name: string }> = {
  GMA1: { id: 'GMA1', name: '中和' },
  GMB1: { id: 'GMB1', name: '板橋車站' },
  GMC1: { id: 'GMC1', name: '新左營車站' },
  GMA2: { id: 'GMA2', name: '屏東市' },
  GMG1: { id: 'GMG1', name: '南港車站' },
  GMA4: { id: 'GMA4', name: '桃園A8' },
  GMA5: { id: 'GMA5', name: '林口A9' },
  GMA6: { id: 'GMA6', name: '桃園A19' },
};

export default function useAdmin() {
  const authUser = useRecoilValue(authUserAtom);
  const setStoreList = useSetRecoilState(storeListAtom);

  const getStoreDocsAsync = async (uid: string) => {
    const storeDocs = await getStoreDocs(uid);
    const storeList = storeDocs.map(
      (doc) => storeIdMap[doc.id] || { id: doc.id, name: doc.data().name },
    );
    setStoreList(storeList);
  };

  useEffect(() => {
    if (authUser) {
      getStoreDocsAsync(authUser.uid);
    } else {
      setStoreList([]);
    }
  }, [authUser]);
}
