import React from 'react';
import { Center } from '@chakra-ui/react';

import baseStyle from '../styles/baseStyle';

type PeriodDayProps = {
  day: number;
};

const periodDay: React.FC<PeriodDayProps> = ({ day }) => {
  return (
    <Center {...baseStyle} color="trip.black" cursor="pointer">
      <Center w="inherit" h={9} bg="admin.red.100">
        {day}
      </Center>
    </Center>
  );
};

export default periodDay;
