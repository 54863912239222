import React from 'react';
import { Center, SimpleGrid } from '@chakra-ui/react';

import { format, addDays, parse, getTime, addYears } from 'date-fns';

import { getDateList } from '../utils/getDateList';
import GrayDay from './days/GrayDay';
import Day from './days';

import type { CalendarDate } from '../type';

type CalendarProps = {
  year: number;
  month: number;
  rangeDate: CalendarDate;
  selectedStartDate?:string;
  onSetRangeDate: (rangeDate: CalendarDate) => void;
};

const Calendar: React.FC<CalendarProps> = ({ year, month, rangeDate, onSetRangeDate,selectedStartDate }) => {
  const calendarDays = getDateList(year, month);

  const startTime = getTime(parse(rangeDate.start, 'yyyy/MM/dd', new Date()));
  const endTime = rangeDate.end ? getTime(parse(rangeDate.end, 'yyyy/MM/dd', new Date())) : null;

  const selectedStartDateNumber = selectedStartDate ? getTime(parse(selectedStartDate, 'yyyy/MM/dd', new Date())) : null;
  const handlePickDate = (time: number, type: 'start' | 'end' | 'restart') => {
    const formattedDate = format(new Date(time), 'yyyy/MM/dd');

    switch (type) {
      case 'start':
        return onSetRangeDate({ ...rangeDate, start: formattedDate });

      case 'end':
        if (time < startTime) {
          return onSetRangeDate({
            start: formattedDate,
            end: rangeDate.start,
          });
        }

        return onSetRangeDate({ ...rangeDate, end: formattedDate });

      case 'restart':
        return onSetRangeDate({ start: formattedDate, end: null });

      default:
        return;
    }
  };

  return (
    <SimpleGrid columns={7}>
      {calendarDays.map(({ day, time, formatted, isThisMonth }) => {
        if (!isThisMonth) {
          return <Center key={formatted} w={10} h={10} />;
        }

        return (
          <Day
            key={formatted}
            startTime={startTime}
            endTime={endTime}
            day={day}
            time={time}
            formatted={formatted}
            onUpdateDate={handlePickDate}
            selectedStartDateNumber={selectedStartDateNumber}
          />
        );
      })}
    </SimpleGrid>
  );
};

export default Calendar;
