import React, { FC } from 'react';
import { Center } from '@chakra-ui/react';

type PageNumberProps = {
  page: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

const PageNumber: FC<PageNumberProps> = ({
  page,
  currentPage,
  setCurrentPage,
}) => {
  const isCurrentPageStyles =
    page === currentPage
      ? {
          border: '1px solid',
          borderRadius: 'sm',
          borderColor: 'admin.primary',
        }
      : {};

  return (
    <Center
      key={page}
      w={6}
      h={6}
      fontSize='xs'
      cursor='pointer'
      {...isCurrentPageStyles}
      _hover={{
        border: '1px solid',
        borderRadius: 'sm',
        borderColor: 'admin.primary',
      }}
      onClick={() => setCurrentPage(page)}
    >
      {page}
    </Center>
  );
};

export default PageNumber;
