import type { FC, ReactElement } from 'react';

import {
  Box,
  Text,
  Collapse,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  HStack,
  Divider,
  VStack,
} from '@chakra-ui/react';
import { OrderItem } from '../type';
import OrderDetailItemField from './OrderDetailItemField';

export type OrderDetailItemsProps = {
  items: Array<OrderItem>;
};

const OrderDetailItems: FC<OrderDetailItemsProps> = ({ items }) => {
  return (
    <Accordion allowMultiple>
      <AccordionItem>
        {({ isExpanded }) => (
          <>
            <AccordionButton
              h="2.4rem"
              bg="admin.gray.50"
              _focus={{ boxShadow: '0px 0px 1px 1px #C8161D' }}
            >
              <HStack justify="flex-end" align="center" w="full">
                <Text fontSize="sm">{isExpanded ? '收起品項' : '展開品項'}</Text>
                <AccordionIcon />
              </HStack>
            </AccordionButton>
            <AccordionPanel py={2}>
              <VStack
                align="stretch"
                justify="space-between"
                spacing={2}
                mt={2}
                divider={<Divider />}
              >
                {items.map(({ count, name, price, modifiers, note }) => (
                  <OrderDetailItemField
                    count={count}
                    name={name}
                    price={price}
                    modifiers={modifiers}
                    note={note}
                  />
                ))}
              </VStack>
            </AccordionPanel>
            {isExpanded && <Box h="2.4rem" bg="admin.gray.50" />}
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default OrderDetailItems;
