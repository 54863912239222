import { useState, useMemo } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerContent,
  DrawerCloseButton,
  DrawerOverlay,
  Box,
  Button,
  Text,
  DrawerHeader,
  VStack,
  Center,
} from '@chakra-ui/react';
import { addMonths, getTime, startOfQuarter, getYear, getMonth, subMonths } from 'date-fns';
import { CalendarDate } from './type';
import Week from './components/Week';
import Calendar from './components/Calendar';

type MobileProps = {
  rangeDate: CalendarDate;
  isOpen: boolean;
  onSetRangeDate: (rangeDate: CalendarDate) => void;
  onClose: () => void;
  selectedStartDate?: string;
};

export default function Mobile({
  isOpen,
  onSetRangeDate,
  onClose,
  rangeDate,
  selectedStartDate,
}: MobileProps) {
  const [date, setDate] = useState(new Date());

  const resetMonthTime = Array.from({ length: 4 }, (_, index) => {
    return getTime(subMonths(new Date(), index));
  }).reverse();

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent h="full">
        <DrawerHeader p={0}>
          <Box width="full" bg="admin.primary" p={2}>
            <Center p={1}>
              <Text fontSize="sm" fontWeight="extrabold" color="white">
                {selectedStartDate ? '結束日期' : '開始日期'}
              </Text>
            </Center>
          </Box>
        </DrawerHeader>
        <DrawerCloseButton
          mt={12}
          _focus={{
            boxShadow: 'none',
          }}
        />
        <DrawerBody>
          <VStack align="stretch">
            <Box>
              {resetMonthTime.map((time) => {
                const year = getYear(time);
                const month = getMonth(time) + 1;
                const monthTitle = `${year} ${month}月`;

                return (
                  <Box key={monthTitle}>
                    <Text>{monthTitle}</Text>

                    <Box w="280px" mx="auto">
                      <Week />

                      <Calendar
                        year={year}
                        month={month}
                        rangeDate={rangeDate}
                        onSetRangeDate={onSetRangeDate}
                        selectedStartDate={selectedStartDate}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </VStack>
        </DrawerBody>
        <DrawerFooter p={0}>
          <Box as="button" onClick={onClose} p={3} width="full" borderTop="solid 1px #f3f3f3">
            <Center>確定</Center>
          </Box>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
