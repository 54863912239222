import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Text, Box, Input, useToast } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import cuid from 'cuid';

import CustomDrawer from '~/components/UI/Drawer';
import { Category } from '~/types/store';
import { NewCategory } from './types';
import { selectedStoreIdAtom } from '~/recoil/atom';
import { singleMenuSelector } from '~/recoil/selector/menu';
import { addNewCategoryInMenu } from '~/firebase/store';

type NewCategoryProps = {
  isOpen: boolean;
  onClose: () => void;
  code: string;
};

const formDefaultValue: NewCategory = {
  name: '',
};

const NewCategoryDrawer: React.FC<NewCategoryProps> = ({ isOpen, onClose, code }) => {
  const { control, handleSubmit } = useForm<NewCategory>({
    defaultValues: formDefaultValue,
    shouldUnregister: true,
  });

  const validateNoSpace = (name: string) => {
    return name.trim().length !== 0;
  };

  const toast = useToast();
  const { menuId = '' } = useParams();
  const storeId = useRecoilValue(selectedStoreIdAtom);
  const { categories: menuCategories = [] } =
    useRecoilValue(singleMenuSelector(menuId || '')) || {};

  const handleSaveNewCategory = async (data: NewCategory) => {
    const newCategoryId = `${code}${cuid()}`;
    const newCategory: Category = {
      name: data.name.trim(),
      items: [],
      code,
    };
    const newCategoriesInMenu = [newCategoryId, ...menuCategories];
    try {
      await addNewCategoryInMenu({
        storeId,
        menuId,
        newCategoryId,
        newCategoriesInMenu,
        newCategory,
      });
      toast({
        position: 'top',
        title: '已新增類別',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.log(error);
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <CustomDrawer
      title="類別名稱"
      isOpen={isOpen}
      onClose={onClose}
      onSave={handleSubmit(handleSaveNewCategory)}
    >
      <Box as="label" justifyContent="space-between">
        <Text mb="0.2rem" htmlFor="name" color="#8F8F8F" fontSize="md">
          類別名稱
        </Text>
        <Controller
          control={control}
          name="name"
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <Input {...field} isInvalid={!!error} errorBorderColor="admin.error" />
          )}
          rules={{ required: true, validate: (name) => validateNoSpace(name) }}
        />
      </Box>
    </CustomDrawer>
  );
};

export default NewCategoryDrawer;
