import React from 'react';
import { Center, Text } from '@chakra-ui/react';

import baseStyle from '../styles/baseStyle';

type OnlyStartDayProps = {
  day: number;
};

const OnlyStartDay: React.FC<OnlyStartDayProps> = ({ day }) => {
  return (
    <Center
      {...baseStyle}
      cursor="pointer"
      position="relative"
      _before={{
        content: '""',
        bg: 'admin.primary',
        borderRadius: 'full',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: '40px',
      }}
      color="white"
    >
      <Text position="relative" color="white">
        {day}
      </Text>
    </Center>
  );
};

export default OnlyStartDay;
