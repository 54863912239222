// doc https://chakra-ui.com/docs/theming/component-style

import { ComponentStyleConfig } from '@chakra-ui/react';
import Button from './Button';
import Input from './Input';
import Text from './Text';
import Heading from './Heading';
import Link from './Link';
import Switch from './Switch';
import Checkbox from './Checkbox';
import Radio from './Radio';
import NumberInput from './NumberInput';
import Table from './Table';
import Tabs from './Tabs';
import Textarea from './Textarea';

const components: { [key: string]: ComponentStyleConfig } = {
  Button,
  Input,
  Text,
  Heading,
  Link,
  Switch,
  Checkbox,
  Radio,
  NumberInput,
  Table,
  Tabs,
  Textarea,
};

export default components;
