import type { FC, ReactElement } from 'react';

import { HStack, Text, Divider } from '@chakra-ui/react';

export type OrderDetailFieldProps = {
  title: string;
  render: ReactElement;
  isMultiple?: boolean;
};

const OrderDetailField: FC<OrderDetailFieldProps> = ({ title, render, isMultiple = false }) => {
  return (
    <HStack spacing={7} align={isMultiple ? 'flex-start' : 'center'}>
      <Text w={{ base: '60px', lg: '96px' }} color="#747474" fontSize="sm">
        {title}
      </Text>
      <Divider h={5} orientation="vertical" />
      {render}
    </HStack>
  );
};

export default OrderDetailField;
