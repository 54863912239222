import { selectorFamily, selector } from 'recoil';
import { Modifier, ModifierWithId } from '~/types/store';
import pickBy from 'lodash/pickBy';
import { storeAtom, selectedMenuIdAtom } from '../atom';

const modifiersSelector = selector<Record<string, Modifier>>({
  key: 'modifiers',
  get: ({ get }) => {
    const store = get(storeAtom);
    const menuId = get(selectedMenuIdAtom);
    if (!store || !menuId) return {};
    const targetCode = store.menus[menuId].code;
    return pickBy(store.modifiers, (modifier) => modifier.code === targetCode);
  },
});

const singleModifierSelector = selectorFamily<ModifierWithId | null, string>({
  key: 'modifier',
  get:
    (targetId) =>
    ({ get }) => {
      const store = get(storeAtom);
      if (!store) return null;
      return store.modifiers[targetId] ? { ...store.modifiers[targetId], id: targetId } : null;
    },
});

export { modifiersSelector, singleModifierSelector };
