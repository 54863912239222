import { useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useDisclosure, Box } from '@chakra-ui/react';

import { ModifierListTable } from '~/components/ModifierList';
import { NewModifierDrawer } from '~/components/OperationDrawer/Modifier';
import { EditItemDrawer } from '~/components/OperationDrawer/Item';
import ListOperation from '~/components/ListOperation';
import TitleOperation from '~/components/TitleOperation';

import { singleItemSelector } from '~/recoil/selector/item';

const ModifierList = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenEditItem,
    onOpen: onOpenEditItem,
    onClose: onCloseEditItem,
  } = useDisclosure();
  const { menuId, categoryId, itemId } = useParams();
  const item = useRecoilValue(singleItemSelector(itemId || ''));
  if (!item) {
    return <Navigate to={`/menu/${menuId}/${categoryId}`} />;
  }

  const { name, modifiers, code } = item;
  return (
    <>
      <Box mb="15px" bg="white" p={{ base: '14px', lg: '16px 25px' }}>
        <TitleOperation
          title={name}
          onBtnClick={onOpenEditItem}
          backButton
          buttonTitle="編輯品項"
        />
      </Box>

      <Box mx={5} my={4} bg="white" overflowX="auto">
        <ListOperation
          title={`選項列表（${modifiers.length}）`}
          btnLabel="新增選項"
          onBtnClick={onOpen}
        />
        <ModifierListTable item={item} />
      </Box>
      <NewModifierDrawer
        isOpen={isOpen}
        onClose={onClose}
        modifiersInItem={modifiers}
        code={code}
      />
      <EditItemDrawer isOpen={isOpenEditItem} onClose={onCloseEditItem} item={item} />
    </>
  );
};

export default ModifierList;
