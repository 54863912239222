import { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Center,
  HStack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  Image,
} from '@chakra-ui/react';

import Logo from '~/assets/images/globalMallSmallIcon.png';

type MenuProps = {
  onClose?: () => void;
};

const menuList = [
  {
    route: '/',
    color: 'admin.black.700',
    name: '今日訂單總覽',
  },
  {
    route: '/operatingReport',
    color: 'admin.black.700',
    name: '過去訂單',
  },
  {
    route: '/menu',
    color: 'admin.black.700',
    name: '專櫃菜單管理',
  },
];

const Menu: FC<MenuProps> = ({ onClose }) => {
  // react router dom
  const navigate = useNavigate();
  const location = useLocation();

  const handleClickMenuItem = (route: string) => {
    navigate(route);
    if (onClose) {
      onClose();
    }
  };

  const CustomAccordionButton = ({
    route,
    color,
    name,
  }: {
    route: string;
    color: string;
    name: string;
  }) => {
    return (
      <AccordionButton
        key={route}
        p={0}
        bg={route.split('/')[1] === location.pathname.split('/')[1] ? 'admin.black.800' : color}
        cursor="pointer"
        _hover={{ bg: 'admin.black.700' }}
        _focus={{
          boxShadow: 'none',
        }}
      >
        <Text
          fontSize="sm"
          color="white"
          pl={8}
          py={4}
          w="100%"
          textAlign="left"
          onClick={() => handleClickMenuItem(route)}
        >
          {name}
        </Text>
      </AccordionButton>
    );
  };

  return (
    <Box minH="full" bg="admin.black.400" py={5}>
      {/* title */}
      <HStack spacing={2.5} px={5} mb={4}>
        <Center w={6} h={6} bg="white" borderRadius="base">
          <Image src={Logo} />
        </Center>
        <Text color="white" fontWeight="bold">
          Global mall 店家管理
        </Text>
      </HStack>

      <Accordion allowToggle>
        {menuList.map((item) => (
          <AccordionItem border="none">{CustomAccordionButton(item)}</AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default Menu;
