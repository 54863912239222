import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import useAuth from '~/hooks/useAuth';

import { authUserAtom, loginInitAtom } from '~/recoil/atom';

const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const navigate = useNavigate();
  const authUser = useRecoilValue(authUserAtom);
  const loginInit = useRecoilValue(loginInitAtom);

  useAuth();
  React.useEffect(() => {
    if (!authUser && loginInit === true) {
      navigate('/login');
    }
    authUser && loginInit && navigate('/');
  }, [authUser, loginInit]);

  return <>{children}</>;
};

export default AuthProvider;
