import { useState, useEffect } from 'react';
import { Link as ReachLink, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Link,
  Center,
  Checkbox,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import cuid from 'cuid';

import { ConfirmModal } from '~/components/UI/Modal';
import Pagination from '~/components/UI/Pagination';
import OperationButtonGroup from '~/components/UI/OperationButtonGroup';

import { selectedStoreIdAtom } from '~/recoil/atom';
import { itemsSelector } from '~/recoil/selector/item';
import { ItemWithId, CategoryWithId } from '~/types/store';

import {
  toggleItemInCategory,
  deleteItem,
  copyItem,
  changeOrderOfItemsInCategory,
  firebaseFieldValue,
} from '~/firebase/store';

import omit from 'lodash/omit';

const ItemListTable = ({ category }: { category: CategoryWithId }) => {
  // chakra hook
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { menuId } = useParams();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [readyToDeleteItem, setReadyToDeleteItem] = useState<{ id: string; name: string } | null>(
    null,
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [category]);

  const storeId = useRecoilValue(selectedStoreIdAtom);

  const items = useRecoilValue(itemsSelector);
  const { id: categoryId = '', items: itemsInCategory = [] } = category;

  const itemsNotInCategory = Object.keys(omit(items, itemsInCategory));
  const allItems = [...itemsInCategory, ...itemsNotInCategory];
  const displayItems = allItems.slice((currentPage - 1) * 10, currentPage * 10);

  const handleToggleInCategory = async (targetId: string, targetName: string) => {
    const isInclude = itemsInCategory.includes(targetId);
    const updateObject = isInclude
      ? firebaseFieldValue.arrayRemove(targetId)
      : [targetId, ...itemsInCategory];
    try {
      await toggleItemInCategory({ storeId, categoryId, updateObject });
      toast({
        position: 'top',
        title: isInclude
          ? `${targetName}已被移除出${category.name}`
          : `${targetName}已成功加入${category.name}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleOpenDeleteModal = (id: string, name: string) => {
    setReadyToDeleteItem({ id, name });
    onOpen();
  };

  const handleConfirmDelete = async () => {
    if (readyToDeleteItem === null) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      await deleteItem({ storeId, itemId: readyToDeleteItem.id });
      toast({
        position: 'top',
        title: `「${readyToDeleteItem.name}」已刪除`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    setReadyToDeleteItem(null);
    onClose();
  };

  const handleCopy = async (item: ItemWithId) => {
    const newItemId = cuid();
    let { id, ...restOfItem } = item;
    restOfItem.name = `${item.name}（1）`;

    const isIncludeInCategory = itemsInCategory.includes(id);
    const newItemsInCategory = isIncludeInCategory ? [...itemsInCategory] : [];
    if (isIncludeInCategory) {
      const index = itemsInCategory.indexOf(id) + 1;
      newItemsInCategory.splice(index, 0, newItemId);
    }

    try {
      await copyItem({
        storeId,
        categoryId,
        isIncludeInCategory,
        newItemId,
        newItemsInCategory,
        newItemObject: restOfItem,
      });
      toast({
        position: 'top',
        title: `「${item.name}」已複製`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDragEnd = async ({ draggableId: itemId, source, destination }: DropResult) => {
    if (!destination || source.index === destination.index) {
      return;
    }
    const sourceIndex = source.index + (currentPage - 1) * 10;
    const destinationIndex = destination.index + (currentPage - 1) * 10;
    const newItemsInCategory = [...itemsInCategory];
    newItemsInCategory.splice(sourceIndex, 1);
    newItemsInCategory.splice(destinationIndex, 0, itemId);
    try {
      await changeOrderOfItemsInCategory({ storeId, categoryId, newItems: newItemsInCategory });
      toast({
        position: 'top',
        title: '操作成功',
        status: 'success',
        duration: 1000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const SingleLine = ({ id: itemId, ...item }: ItemWithId) => (
    <>
      <Td px={{ base: 1, lg: 4 }} textAlign="center">
        <Checkbox
          size="lg"
          isChecked={itemsInCategory.includes(itemId)}
          onChange={() => handleToggleInCategory(itemId, item.name)}
          colorScheme="tableStatus"
        />
      </Td>
      <Td px={{ base: 1, lg: 4 }}>
        <Link as={ReachLink} to={`/menu/${menuId}/${categoryId}/${itemId}`} wordBreak="break-all">
          {item.name}
        </Link>
      </Td>
      <Td px={{ base: 1, lg: 4 }} wordBreak="break-all">
        $ {item.price}
      </Td>
      <Td px={{ base: 1, lg: 4 }}>
        <OperationButtonGroup
          isInclude={itemsInCategory.includes(itemId)}
          onClickCopy={() => handleCopy({ id: itemId, ...item })}
          onClickDelete={() => handleOpenDeleteModal(itemId, item.name)}
        />
      </Td>
    </>
  );

  return (
    <>
      {allItems.length > 0 ? (
        <>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr h={10}>
                <Th w="20%" px={{ base: 1, lg: 4 }} textAlign="center">
                  勾選
                </Th>
                <Th w="40%" px={{ base: 1, lg: 4 }}>
                  品項名稱
                </Th>
                <Th w="20%" px={{ base: 1, lg: 4 }}>
                  售價
                </Th>
                <Th w="20%" px={{ base: 1, lg: 4 }}>
                  操作
                </Th>
              </Tr>
            </Thead>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <Tbody bg="white" {...provided.droppableProps} ref={provided.innerRef}>
                    {displayItems.map((id, index) =>
                      items[id] ? (
                        <Draggable
                          key={id}
                          draggableId={id}
                          index={index}
                          isDragDisabled={itemsNotInCategory.includes(id)}
                        >
                          {(provided) => (
                            <Tr
                              h={14}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{ cursor: 'default', ...provided.draggableProps.style }}
                            >
                              {SingleLine({ id, ...items[id] })}
                            </Tr>
                          )}
                        </Draggable>
                      ) : (
                        <></>
                      ),
                    )}
                    {provided.placeholder}
                  </Tbody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            maximumPageNumber={Math.ceil(allItems.length / 10)}
          />
        </>
      ) : (
        <Center h={16}>
          <Text>暫無品項</Text>
        </Center>
      )}
      <ConfirmModal
        isOpen={isOpen}
        content={
          <Text pb={7} textAlign="center" fontSize="xl" fontWeight="bold" lineHeight="24px">
            確定刪除品項「{readyToDeleteItem?.name || ''}」？
          </Text>
        }
        modalContentStyles={{ maxW: '380px' }}
        modalBodyStyles={{ pt: 7, pb: 5, px: '30px' }}
        onConfirm={handleConfirmDelete}
        onClose={onClose}
      />
    </>
  );
};

export default ItemListTable;
