import React, { FC, useState } from 'react';
import { HStack, Box, Center, Icon } from '@chakra-ui/react';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai';

type EllipsisProps = {
  position: 'left' | 'right';
  pages: number[];
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

const iconBaseStyles = {
  w: 3,
  h: 2,
  color: 'admin.primary',
  _hover: {
    color: 'admin.blue.700',
  },
};

const Ellipsis: FC<EllipsisProps> = ({ position, pages, setCurrentPage }) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const handleShowFastForward = () => {
    setIsHovering(true);
  };

  const handleHideFastForward = () => {
    setTimeout(() => {
      setIsHovering(false);
    }, 500);
  };

  const handlePreviousFivePage = () => {
    setCurrentPage((prev) => (prev < 5 ? 1 : prev - 5));
  };

  const handleNextFivePage = () => {
    setCurrentPage((prev) => (pages.length - prev < 5 ? pages.length : prev + 5));
  };

  if (isHovering && position === 'left') {
    return (
      <Center
        w={5}
        cursor="pointer"
        onClick={handlePreviousFivePage}
        onMouseLeave={handleHideFastForward}
      >
        <Icon as={AiOutlineDoubleLeft} {...iconBaseStyles} />
      </Center>
    );
  }

  if (isHovering && position === 'right') {
    return (
      <Center
        w={5}
        cursor="pointer"
        onClick={handleNextFivePage}
        onMouseLeave={handleHideFastForward}
      >
        <Icon as={AiOutlineDoubleRight} {...iconBaseStyles} />
      </Center>
    );
  }

  return (
    <HStack spacing={1} justify="center" align="center" onMouseEnter={handleShowFastForward}>
      {Array.from({ length: 3 }).map((_, i) => (
        <Box key={i} w={1} h={1} bg="admin.gray.500" borderRadius="full" />
      ))}
    </HStack>
  );
};

export default Ellipsis;
