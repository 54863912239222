import { useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useDisclosure, Box } from '@chakra-ui/react';

import { CategoryListTable } from '~/components/CategoryList';
import { NewCategoryDrawer } from '~/components/OperationDrawer/Category';
import ListOperation from '~/components/ListOperation';
import TitleOperation from '~/components/TitleOperation';

import { selectedMenuIdAtom } from '~/recoil/atom';
import { singleMenuSelector } from '~/recoil/selector/menu';

const CategoryList = () => {
  const {
    isOpen: isOpenNewCategory,
    onOpen: onOpenNewCategory,
    onClose: onCloseNewCategory,
  } = useDisclosure();

  const params = useParams();
  const { menuId = '' } = params;
  const setMenuId = useSetRecoilState(selectedMenuIdAtom);
  const menu = useRecoilValue(singleMenuSelector(menuId));

  useEffect(() => {
    if (menuId !== '') {
      setMenuId(menuId);
    }
  }, [menuId]);

  if (!menu) {
    return <Navigate to="/menu" />;
  }

  const { name = '', categories = [], code } = menu;

  return (
    <>
      <Box mb="15px" bg="white" p={{ base: '14px', lg: '16px 25px' }}>
        <TitleOperation title={name} isEditable="none" backButton />
      </Box>

      <Box mx={5} my={4} bg="white" overflowX="auto">
        <ListOperation
          title={`類別列表（${categories.length}）`}
          btnLabel="新增類別"
          onBtnClick={onOpenNewCategory}
        />
        <CategoryListTable menu={menu} />
      </Box>
      <NewCategoryDrawer isOpen={isOpenNewCategory} onClose={onCloseNewCategory} code={code} />
    </>
  );
};

export default CategoryList;
