import { useState, useEffect } from 'react';
import { Link as ReachLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Link,
  Center,
  Checkbox,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { ConfirmModal } from '~/components/UI/Modal';
import Pagination from '~/components/UI/Pagination';
import OperationButtonGroup from '../UI/OperationButtonGroup';

import { selectedStoreIdAtom } from '~/recoil/atom';
import { categoriesSelector } from '~/recoil/selector/category';
import { MenuWithId, CategoryWithId } from '~/types/store';

import {
  toggleCategoryInMenu,
  deleteCategory,
  copyCategory,
  changeOrderOfCategoriesInMenu,
  firebaseFieldValue,
} from '~/firebase/store';

import cuid from 'cuid';
import omit from 'lodash/omit';

const CategoryListTable = ({ menu }: { menu: MenuWithId }) => {
  // chakra hook
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [readyToDeleteCategory, setReadyToDeleteCategory] = useState<{
    id: string;
    name: string;
  } | null>(null);

  useEffect(() => {
    setCurrentPage(1);
  }, [menu]);

  const storeId = useRecoilValue(selectedStoreIdAtom);
  const categories = useRecoilValue(categoriesSelector);

  const { id: menuId = '', categories: categoriesInMenu = [] } = menu;
  const categoriesNotInMenu = Object.keys(omit(categories, categoriesInMenu));
  const allCategories = [...categoriesInMenu, ...categoriesNotInMenu];
  const displayCategories = allCategories.slice((currentPage - 1) * 10, currentPage * 10);

  const handleToggleInMenu = async (targetId: string) => {
    const isInclude = categoriesInMenu.includes(targetId);
    const updateObject = isInclude
      ? firebaseFieldValue.arrayRemove(targetId)
      : [targetId, ...categoriesInMenu];
    try {
      await toggleCategoryInMenu({ storeId, menuId, updateObject });
      toast({
        position: 'top',
        title: '操作成功',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleOpenDeleteModal = (id: string, name: string) => {
    setReadyToDeleteCategory({ id, name });
    onOpen();
  };

  const handleConfirmDelete = async () => {
    if (readyToDeleteCategory === null) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      await deleteCategory({
        storeId: storeId,
        categoryId: readyToDeleteCategory.id,
      });
      toast({
        position: 'top',
        title: `「${readyToDeleteCategory.name}」已刪除`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    setReadyToDeleteCategory(null);
    onClose();
  };

  const handleCopy = async (category: CategoryWithId) => {
    const { id: categoryId, ...restOfCategory } = category;
    const newCategoryId = cuid();
    const isIncludeInMenu = categoriesInMenu.includes(categoryId);
    const newCategoryInMenu = isIncludeInMenu ? [...categoriesInMenu] : [];
    isIncludeInMenu &&
      newCategoryInMenu.splice(categoriesInMenu.indexOf(categoryId) + 1, 0, newCategoryId);
    try {
      await copyCategory({
        storeId,
        menuId,
        isIncludeInMenu,
        newCategoryInMenu,
        newCategoryId,
        newCategoryObject: { ...restOfCategory, name: `${restOfCategory.name}（1）` },
      });
      toast({
        position: 'top',
        title: `「${category.name}」已複製`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDragEnd = async ({ draggableId: categoryId, source, destination }: DropResult) => {
    if (!destination || !menu || source.index === destination.index) {
      return;
    }
    const sourceIndex = source.index + (currentPage - 1) * 10;
    const destinationIndex = destination.index + (currentPage - 1) * 10;
    const newCategoryInMenu = [...categoriesInMenu];
    newCategoryInMenu.splice(sourceIndex, 1);
    newCategoryInMenu.splice(destinationIndex, 0, categoryId);
    try {
      await changeOrderOfCategoriesInMenu({ storeId, menuId, newCategories: newCategoryInMenu });
      toast({
        position: 'top',
        title: '操作成功',
        status: 'success',
        duration: 1000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const SingleLine = (category: CategoryWithId) => (
    <>
      <Td px={{ base: 2, lg: 4 }} textAlign="center">
        <Checkbox
          size="lg"
          isChecked={categoriesInMenu.includes(category.id)}
          onChange={() => handleToggleInMenu(category.id)}
          colorScheme="tableStatus"
        />
      </Td>
      <Td px={{ base: 2, lg: 4 }}>
        <Link as={ReachLink} to={`/menu/${menuId}/${category.id}`} wordBreak="break-all">
          {category.name}
        </Link>
      </Td>
      <Td px={{ base: 2, lg: 4 }}>
        <OperationButtonGroup
          isInclude={categoriesInMenu.includes(category.id)}
          onClickCopy={() => handleCopy(category)}
          onClickDelete={() => handleOpenDeleteModal(category.id, category.name)}
        />
      </Td>
    </>
  );

  return (
    <>
      {allCategories.length > 0 ? (
        <>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr h={10}>
                <Th w="20%" px={{ base: 2, lg: 4 }} textAlign="center">
                  勾選
                </Th>
                <Th w="55%" px={{ base: 2, lg: 4 }}>
                  類別名稱
                </Th>
                <Th w="25%" px={{ base: 2, lg: 4 }}>
                  操作
                </Th>
              </Tr>
            </Thead>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <Tbody bg="white" {...provided.droppableProps} ref={provided.innerRef}>
                    {displayCategories.map((id, index) =>
                      categories[id] ? (
                        <Draggable
                          key={id}
                          draggableId={id}
                          index={index}
                          isDragDisabled={categoriesNotInMenu.includes(id)}
                        >
                          {(provided) => (
                            <Tr
                              h={14}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{ cursor: 'default', ...provided.draggableProps.style }}
                            >
                              {SingleLine({ id, ...categories[id] })}
                            </Tr>
                          )}
                        </Draggable>
                      ) : (
                        <></>
                      ),
                    )}
                    {provided.placeholder}
                  </Tbody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            maximumPageNumber={Math.ceil(allCategories.length / 10)}
          />
        </>
      ) : (
        <Center h={16}>
          <Text>暫無類別</Text>
        </Center>
      )}
      <ConfirmModal
        isOpen={isOpen}
        content={
          <Text pb={7} textAlign="center" fontSize="xl" fontWeight="bold" lineHeight="24px">
            {`確定刪除「${readyToDeleteCategory?.name}」？`}
          </Text>
        }
        modalContentStyles={{ maxW: '380px' }}
        modalBodyStyles={{ pt: 7, pb: 5, px: '30px' }}
        onConfirm={handleConfirmDelete}
        onClose={onClose}
      />
    </>
  );
};

export default CategoryListTable;
