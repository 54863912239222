import React, { useState, useMemo } from 'react';
import DatePicker from './index';
import { Box, Button, SlideFade, Text, Flex, Icon, Center } from '@chakra-ui/react';
import { getYear, getMonth, addMonths, isSameYear, differenceInMonths } from 'date-fns';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { CalendarDate } from './type';
import Week from './components/Week';
import Calendar from './components/Calendar';

const months = Array.from({ length: 12 }, (_, i) => `${i + 1}月`);

type DesktopProps = {
  rangeDate: CalendarDate;
  isOpen: boolean;
  onSetRangeDate: (rangeDate: CalendarDate) => void;
  onClose: () => void;
  selectedStartDate?: string;
};

export default function Desktop({
  rangeDate,
  isOpen,
  onSetRangeDate,
  onClose,
  selectedStartDate,
}: DesktopProps) {
  const [date, setDate] = useState(new Date());

  // arrow clickable
  const [isLeftArrowClickable, isRightArrowClickable] = useMemo(() => {
    // const currentMonth = getMonth(new Date());
    // const diff = differenceInMonths(date, new Date());

    // if (currentMonth === getMonth(date) && isSameYear(new Date(), date)) {
    //   return [false, true];
    // }

    // if (diff === 10) {
    //   return [true, false];
    // }

    return [true, true];
  }, [date]);

  const handlePreviousMonth = () => {
    if (!isLeftArrowClickable) return;
    setDate((prev) => addMonths(prev, -1));
  };

  const handleNextMonth = () => {
    if (!isRightArrowClickable) return;
    setDate((prev) => addMonths(prev, 1));
  };

  const nextMonthDate = addMonths(date, 1);

  const monthTitle = `${getYear(date)} ${months[getMonth(date)]}`;

  const nextMonthTitle = `${getYear(nextMonthDate)} ${months[getMonth(nextMonthDate)]}`;

  return (
    <SlideFade
      in={isOpen}
      style={{
        position: 'absolute',
        background: 'white',
        display: isOpen ? 'block' : 'none',
        borderRadius: '5px',
      }}
    >
      <Box width="full" bg="admin.primary" borderTopRadius="5px" p={1}>
        <Center p={1}>
          <Text fontSize="sm" fontWeight="extrabold" color="white">
            {selectedStartDate ? '結束日期' : '開始日期'}
          </Text>
        </Center>
      </Box>
      <Flex>
        {/* left */}
        <Box p={5}>
          <Flex alignItems="center" mb={6}>
            <Icon
              as={AiOutlineLeft}
              cursor={isLeftArrowClickable ? 'pointer' : 'not-allowed'}
              color={isLeftArrowClickable ? 'black' : 'gray'}
              onClick={handlePreviousMonth}
            />
            <Text flex="1" textAlign="center">
              {monthTitle}
            </Text>
          </Flex>

          {/* week */}
          <Week />

          {/* calendar */}
          <Calendar
            year={getYear(date)}
            month={getMonth(date) + 1}
            rangeDate={rangeDate}
            onSetRangeDate={onSetRangeDate}
            selectedStartDate={selectedStartDate}
          />
        </Box>

        {/* right */}
        <Box p={5}>
          <Flex alignItems="center" mb={6}>
            <Text flex="1" textAlign="center">
              {nextMonthTitle}
            </Text>
            <Icon
              as={AiOutlineRight}
              cursor={isRightArrowClickable ? 'pointer' : 'not-allowed'}
              color={isRightArrowClickable ? 'black' : 'gray'}
              onClick={handleNextMonth}
            />
          </Flex>

          {/* week */}
          <Week />

          {/* calendar */}
          <Calendar
            year={getYear(nextMonthDate)}
            month={getMonth(nextMonthDate) + 1}
            rangeDate={rangeDate}
            onSetRangeDate={onSetRangeDate}
            selectedStartDate={selectedStartDate}
          />
        </Box>
      </Flex>
      <Box as="button" onClick={onClose} p={3} width="full" borderTop="solid 1px #f3f3f3">
        <Center>確定</Center>
      </Box>
    </SlideFade>
  );
}
