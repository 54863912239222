import firebase from 'firebase';
import { firebaseAuth } from './initialize';

export const loginAsync = async ({
  email,
  password,
  isRememberMe,
}: {
  email: string;
  password: string;
  isRememberMe: boolean;
}) => {
  await firebaseAuth.setPersistence(
    isRememberMe ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION,
  );
  await firebaseAuth.signInWithEmailAndPassword(email, password);
};

export const logoutAsync = async () => await firebaseAuth.signOut();

export const sendResetPasswordEMail = async (email: string) =>
  await firebaseAuth.sendPasswordResetEmail(email);
