import { useState } from 'react';
import {
  Text,
  useDisclosure,
  VStack,
  Textarea,
  Checkbox,
  CheckboxGroup,
  useToast,
} from '@chakra-ui/react';
import { ConfirmModal } from '~/components/UI/Modal';
import { cancelOrder } from '~/firebase/order';

import { PaymentType } from '../type';

const CancelButton = ({ method, orderId }: { method: PaymentType; orderId: string }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const options: string[] = ['材料不足', '即將打烊'];

  const [reasonForCancelling, setReason] = useState<string[]>([]);
  const [elseReason, setElseReason] = useState('');

  const handleCheckedItems = (value: string[]) => {
    const inputValue = value;
    setReason(inputValue);
  };

  const handleElseChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const elseReason = e.target.value;
    setElseReason(elseReason);
  };

  // confirm delete
  const handleConfirm = async () => {
    const index = reasonForCancelling.indexOf('其他');
    if (index !== -1 && elseReason.trim() !== '') {
      reasonForCancelling[index] = elseReason;
    }

    try {
      await cancelOrder({ orderId, reasonForCancelling });
      toast({
        title: '取消退款成功',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    } catch (error) {
      console.log(error);
      toast({
        title: '伺服器錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    }
    onClose();
  };

  return (
    <>
      <Text
        color="admin.red.700"
        fontSize="sm"
        cursor="pointer"
        _hover={{ textDecoration: 'underline' }}
        onClick={onOpen}
      >
        取消退款
      </Text>

      {method !== PaymentType.CASH && (
        <ConfirmModal
          content={
            <VStack spacing={4} mb={6} align="stretch">
              <Text fontSize="lg" fontWeight="bold">
                選擇取消原因
              </Text>
              <Text>選擇線上付款的訂單，退款的同時也會取消該筆訂單</Text>
              <CheckboxGroup onChange={handleCheckedItems}>
                <VStack align="stretch" justify="space-between" spacing={5}>
                  {options &&
                    options.map((option) => (
                      <Checkbox key={option} value={option}>
                        {option}
                      </Checkbox>
                    ))}
                  <Checkbox value="其他">其他</Checkbox>
                </VStack>
              </CheckboxGroup>
              <Textarea placeholder="填寫原因" onChange={handleElseChange} />
            </VStack>
          }
          isOpen={isOpen}
          onConfirm={handleConfirm}
          onClose={onClose}
        />
      )}

      {method === PaymentType.CASH && (
        <ConfirmModal
          content={
            <VStack spacing={4} mb={6} align="stretch">
              <Text fontSize="lg" fontWeight="bold">
                選擇取消原因
              </Text>
              <Text>選擇原因後確定取消該筆訂單</Text>
              <CheckboxGroup onChange={handleCheckedItems}>
                <VStack align="stretch" justify="space-between" spacing={5}>
                  {options &&
                    options.map((option) => (
                      <Checkbox key={option} value={option}>
                        {option}
                      </Checkbox>
                    ))}
                  <Checkbox value="其他">其他</Checkbox>
                </VStack>
              </CheckboxGroup>
              <Textarea placeholder="填寫原因" onChange={handleElseChange} />
            </VStack>
          }
          isOpen={isOpen}
          onConfirm={handleConfirm}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default CancelButton;
