import type { ComponentStyleConfig } from '@chakra-ui/react';

const Tabs: ComponentStyleConfig = {
  parts: ['tablist', 'tab'],
  variants: {
    line: {
      tablist: {
        borderBottom: '1px solid',
      },
      tab: {
        p: 4,
        fontSize: 'xs',
        _focus: {
          boxShadow: 'none',
        },
        _selected: {
          color: 'admin.blue.500',
          borderColor: 'admin.blue.500',
        },
      },
    },
    button: {
      tab: {
        mx: 2,
        fontSize: 'xs',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: 'admin.gray.600',
        color: 'admin.gray.600',
        bg: 'transparent',
        fontWeight: '700',
        fontsize: 'xs',
        _focus: {
          boxShadow: 'none',
        },
        _selected: {
          borderColor: 'admin.primary',
          bg: 'admin.primary',
          color: 'white',
        },
      },
    },
  },
};

export default Tabs;
