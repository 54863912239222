import React from 'react';
import { Outlet } from 'react-router-dom';
import useSelectedStoreId from '~/hooks/useSelectedStoreId';
import useStore from '~/hooks/useStore';

const SelectedStoreProvider = () => {
  useSelectedStoreId();
  useStore();
  return <Outlet />;
};

export default SelectedStoreProvider;
