import React from 'react';
import { Flex, Box } from '@chakra-ui/react';

import SearchInput from '~/components/SearchInput';
import { FilterActionType, FilterAction } from '~/components/TodayOrders/utils';

const TodayOrdersSearch = ({
  filterDispatch,
}: {
  filterDispatch: React.Dispatch<FilterActionType>;
}) => {
  const handleSearch = (text: string) => {
    filterDispatch({ type: FilterAction.SEARCH, payload: text });
  };
  return (
    <Box bg="white" py={8}>
      <Flex justify="center">
        <SearchInput placeholder="客戶電話/訂單編號後五碼" onSearch={handleSearch} />
      </Flex>
    </Box>
  );
};

export default TodayOrdersSearch;
