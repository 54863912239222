import React from 'react';
import { HStack, Icon } from '@chakra-ui/react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

import PageContent from './PageContent';

type PaginationProps = {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  maximumPageNumber: number;
};

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  setCurrentPage,
  maximumPageNumber,
}) => {
  const pages = Array.from({ length: maximumPageNumber }).map((_, i) => i + 1);

  const handleClickPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleClickNextPage = () => {
    if (currentPage < pages.length - 1) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  return (
    <HStack spacing={4} justify={{ base: 'center', md: 'flex-end' }} align="center" pt={12} pb={8}>
      <Icon
        as={AiOutlineLeft}
        w={3}
        h={4}
        cursor={currentPage > 1 ? 'pointer' : 'not-allowed'}
        color={currentPage > 1 ? 'admin.black' : 'admin.gray.500'}
        onClick={handleClickPreviousPage}
      />
      <PageContent pages={pages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      <Icon
        as={AiOutlineRight}
        w={3}
        h={4}
        cursor={currentPage < pages.length ? 'pointer' : 'not-allowed'}
        color={currentPage < pages.length ? 'admin.black' : 'admin.gray.500'}
        onClick={handleClickNextPage}
      />
    </HStack>
  );
};

export default Pagination;
