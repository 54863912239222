import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';

import { AiOutlineMenu } from 'react-icons/ai';

import Menu from './Menu';

const MobileDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Icon as={AiOutlineMenu} onClick={onOpen} />

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent overflow="scroll">
          <DrawerCloseButton color="white" />
          <Menu onClose={onClose} />
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MobileDrawer;
