import type { ComponentStyleConfig } from '@chakra-ui/react';

const Table: ComponentStyleConfig = {
  parts: ['th', 'td'],
  baseStyle: {
    th: {
      color: 'admin.black',
      bg: 'admin.gray.200',
      fontSize: 'xs',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },
    td: {
      color: 'admin.black',
      whiteSpace: 'nowrap',
    },
  },
  variants: {
    simple: {
      th: {
        color: 'admin.gray.800',
        fontWeight: '700',
      },
    },
  },

  sizes: {
    sm: {
      th: {
        fontSize: '15px',
        lineHeight: '20px',
        letterSpacing: '0.1em',
      },
      td: {
        fontSize: '15px',
        lineHeight: '20px',
        letterSpacing: '0.1em',
      },
    },
  },
};

export default Table;
