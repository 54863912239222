import { useParams, Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useDisclosure, Box } from '@chakra-ui/react';

import { EditCategoryDrawer } from '~/components/OperationDrawer/Category';
import { NewItemDrawer } from '~/components/OperationDrawer/Item';
import { ItemListTable } from '~/components/ItemList';
import ListOperation from '~/components/ListOperation';
import TitleOperation from '~/components/TitleOperation';

import { singleCategorySelector } from '~/recoil/selector/category';

const ItemList = () => {
  const { isOpen: isOpenNewItem, onOpen: onOpenNewItem, onClose: onCloseNewItem } = useDisclosure();

  const {
    isOpen: isOpenEditDrawer,
    onOpen: onOpenEditDrawer,
    onClose: onCloseEditDrawer,
  } = useDisclosure();
  const { menuId, categoryId } = useParams();
  const category = useRecoilValue(singleCategorySelector(categoryId || ''));

  if (!category) {
    return <Navigate to={`/menu/${menuId}`} />;
  }
  const { name = '', items = [], code } = category;

  return (
    <>
      <Box mb="15px" bg="white" p={{ base: '14px', lg: '16px 25px' }}>
        <TitleOperation
          title={name}
          onBtnClick={onOpenEditDrawer}
          buttonTitle="編輯類別"
          backButton
        />
      </Box>

      <Box mx={5} my={4} bg="white" overflowX="auto">
        <ListOperation
          title={`品項列表（${category.items.length}）`}
          btnLabel="新增品項"
          onBtnClick={onOpenNewItem}
        />
        <ItemListTable category={category} />
      </Box>
      <NewItemDrawer isOpen={isOpenNewItem} onClose={onCloseNewItem} items={items} code={code} />
      <EditCategoryDrawer isOpen={isOpenEditDrawer} onClose={onCloseEditDrawer} name={name} />
    </>
  );
};

export default ItemList;
