import type { ComponentStyleConfig } from '@chakra-ui/react';

const Checkbox: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: 'checkbox',
  },
  baseStyle: {
    _checked: {
      bg: 'admin.primary',
    },
  },
};

export default Checkbox;
