export enum Week {
  SUN = 'SUN',
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
}

export enum PaymentType {
  NONE = 'NONE',
  CASH = 'CASH',
  CREDIT = 'CREDIT',
  LINEPAY = 'LINEPAY',
}

export type Schedule = Partial<Record<Week, Array<{ open: number; close: number }>>>;

export type Inventory = {
  currentInventory: number;
  defaultInventory: number;
};

export type Option = {
  id: string;
  name: string;
  isSoldOut: boolean;
  price: number;
  inventory: Inventory;
};

export type Modifier = {
  name: string;
  max: number;
  min: number;
  required: boolean;
  options: Array<Option>;
  code: string;
};

export type ModifierWithId = { id: string } & Modifier;

export type Item = {
  description: string;
  image: string;
  inventory: Inventory;
  isSoldOut: boolean;
  modifiers: Array<string>;
  name: string;
  price: number;
  tax: number;
  code: string;
};

export type ItemWithId = { id: string } & Item;

export type Category = {
  items: Array<string>;
  name: string;
  code: string;
};

export type CategoryWithId = { id: string } & Category;

export type Menu = {
  name: string;
  allowDelivery: boolean;
  allowPickup: boolean;
  allowPreorder: boolean;
  allowPublish: boolean;
  categories: Array<string>;
  schedule: Schedule;
  kitchenId: string;
  code: string;
};

export type MenuWithId = { id: string } & Menu;

export type Store = {
  allowOrders: boolean;

  name: string;
  currency: string;
  logo: string;
  images: Array<string>;
  description: string;
  phoneNumber: string;

  isDemo: boolean;

  location: {
    address: string;
  };
  operatingTime: Schedule;
  orderNumCount: number;
  orderNumLimit: number;
  orderPrepareMinutes: number;
  paymentTypes: Array<PaymentType>;
  preOrderSetting: {
    allowPreOrder: boolean;
    preOrderAfter: number;
    preOrderBefore: number;
  };
  timezone: string;

  // the store products
  menus: Record<string, Menu>;
  categories: Record<string, Category>;
  items: Record<string, Item>;
  modifiers: Record<string, Modifier>;
  GATrackingId: string;
};

export type StoreWithId = { id: string } & Store;
