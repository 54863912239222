import { ComponentStyleConfig } from '@chakra-ui/react';

export enum InputVariants {
  OUTLINE = 'outline',
}

const Input: ComponentStyleConfig = {
  baseStyle: {
    color: 'admin.black.400',
  },
  variants: {
    [InputVariants.OUTLINE]: {
      field: {
        borderColor: 'admin.gray.50',
      },
    },
  },
  defaultProps: {
    focusBorderColor: 'admin.black.700',
  },
};

export default Input;
