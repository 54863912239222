import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Text,
  Box,
  Input,
  HStack,
  Button,
  VStack,
  Radio,
  RadioGroup,
  useToast,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import cuid from 'cuid';

import { ButtonVariants } from '~/customTheme/components/Button';
import CustomDrawer from '~/components/UI/Drawer';
import { selectedStoreIdAtom } from '~/recoil/atom';
import { Modifier, ModifierWithId, Option } from '~/types/store';
import { EditModifier } from './types';
import { editModifier } from '~/firebase/store';

type EditModifierProps = {
  isOpen: boolean;
  onClose: () => void;
  modifier: ModifierWithId | null;
};

const EditModifierDrawer: React.FC<EditModifierProps> = ({ isOpen, onClose, modifier }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    reset,
    control,
  } = useForm<EditModifier>({
    shouldUnregister: true,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
    shouldUnregister: true,
  });

  const toast = useToast();
  const storeId = useRecoilValue(selectedStoreIdAtom);

  useEffect(() => {
    if (!modifier || !isOpen) {
      return;
    }
    const defaultOptions = modifier.options.map(({ name, price, id }) => {
      return { name, price: price.toString(), id };
    });
    const defaultModifier = {
      name: modifier.name || '',
      required: modifier.required.toString() || '',
      max: modifier.max.toString() || '',
      min: modifier.min.toString() || '',
      options: defaultOptions,
    };
    reset({ ...defaultModifier });
  }, [isOpen]);

  const validateLimit = () => {
    const min = parseInt(getValues('min'));
    const max = parseInt(getValues('max'));

    return !isNaN(min) && !isNaN(max) && min <= max && min <= fields.length;
  };

  const validateNoSpace = (name: string) => {
    return name.trim().length !== 0;
  };

  const validateNoPoint = (name: string) => {
    return !name.includes('.');
  };

  const handleSaveNewModifier = async (data: EditModifier) => {
    if (!modifier) {
      return;
    }
    const newOptions: Option[] = data.options.map(({ name, price, id }) => {
      const originOption = modifier.options.find(({ id: optionId }) => optionId === id);
      return originOption
        ? {
            ...originOption,
            name,
            price: parseInt(price),
          }
        : {
            name,
            price: parseInt(price),
            id: `${modifier.code}${cuid()}`,
            isSoldOut: false,
            inventory: {
              defaultInventory: Infinity,
              currentInventory: Infinity,
            },
          };
    });
    const newModifier: Modifier = {
      code: modifier.code,
      name: data.name.trim(),
      max: parseInt(data.max),
      min: parseInt(data.min),
      required: data.required === 'true' ? true : false,
      options: newOptions,
    };
    try {
      await editModifier({ storeId, modifierId: modifier.id, newModifierObject: newModifier });
      toast({
        position: 'top',
        title: '修改成功',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleNewOption = () => {
    append({ name: '', price: '' });
  };

  const handleDeleteOption = (index: number) => {
    remove(index);
  };

  const customButton = (
    <HStack bg="white" w="100%" justify="space-between" spacing={5} px="0.9rem" pb={4}>
      <Button isFullWidth variant={ButtonVariants.MODAL_PRIMARY} onClick={handleNewOption}>
        + 新增選項
      </Button>
    </HStack>
  );

  return (
    <CustomDrawer
      title="選項詳情設定"
      isOpen={isOpen}
      onClose={onClose}
      onSave={handleSubmit(handleSaveNewModifier)}
      customButton={customButton}
    >
      <Box as="label" justifyContent="space-between">
        <Text mb="0.2rem" htmlFor="name" color="#8F8F8F" fontSize="md">
          選項標題
        </Text>
        <Input
          {...register('name', { required: true, validate: (name) => validateNoSpace(name) })}
          isInvalid={!!errors.name}
          errorBorderColor="admin.error"
          focusBorderColor={!!errors.name ? 'admin.error' : 'admin.black.700'}
        />
      </Box>

      <Box as="label" justifyContent="space-between">
        <Text mb="0.2rem" htmlFor="required" color="#8F8F8F" fontSize="md">
          是否為必填選項？
        </Text>
        <HStack spacing="0.6rem">
          <RadioGroup
            {...register('required')}
            onChange={(e) => setValue('required', e)}
            defaultValue={modifier?.required.toString()}
          >
            <HStack spacing={8} align="center">
              <Radio value="true" isInvalid={!!errors.required}>
                是
              </Radio>
              <Radio value="false" isInvalid={!!errors.required}>
                否
              </Radio>
            </HStack>
          </RadioGroup>
        </HStack>
      </Box>

      <Box as="label" justifyContent="space-between">
        <Text mb="0.2rem" htmlFor="min" color="#8F8F8F" fontSize="md">
          客戶可以選擇幾個選項？
        </Text>
        <HStack spacing={4} align="center">
          <Controller
            control={control}
            name="min"
            render={({ field, fieldState: { error } }) => (
              <NumberInput
                w="3rem"
                {...field}
                isInvalid={!!error}
                errorBorderColor="admin.error"
                focusBorderColor={!!error ? 'admin.error' : 'admin.black.700'}
              >
                <NumberInputField textAlign="center" p={{ base: 0, sm: 4 }} />
              </NumberInput>
            )}
            rules={{ required: true, min: 0, validate: () => validateLimit() }}
          />
          <Text> - </Text>
          <Controller
            control={control}
            name="max"
            render={({ field, fieldState: { error } }) => (
              <NumberInput
                w="3rem"
                {...field}
                isInvalid={!!error}
                errorBorderColor="admin.error"
                focusBorderColor={!!error ? 'admin.error' : 'admin.black.700'}
              >
                <NumberInputField textAlign="center" p={{ base: 0, sm: 4 }} />
              </NumberInput>
            )}
            rules={{ required: true, min: 1, validate: () => validateLimit() }}
          />
        </HStack>
      </Box>

      <Box as="label" justifyContent="space-between">
        <HStack spacing={4} align="center">
          <Text color="#8F8F8F" fontSize="md" w="20rem">
            選項內容
          </Text>
          <Text color="#8F8F8F" fontSize="md" w="14rem">
            選項價錢
          </Text>
        </HStack>
        <VStack spacing={4} align="stretch" mt={1}>
          {fields.map((field, index) => (
            <Box key={field.id}>
              <HStack spacing={4} align="center">
                <Input
                  w="20rem"
                  h={10}
                  {...register(`options.${index}.name` as const, { required: true })}
                  isInvalid={!!(errors.options && errors.options[index]?.name)}
                  errorBorderColor="admin.error"
                  focusBorderColor={!!errors.options ? 'admin.error' : 'admin.black.700'}
                  defaultValue={field.name}
                />
                <Controller
                  control={control}
                  name={`options.${index}.price` as const}
                  render={({ field: controlField, fieldState: { error } }) => (
                    <NumberInput
                      w="7rem"
                      {...controlField}
                      isInvalid={!!error}
                      errorBorderColor="admin.error"
                      focusBorderColor={!!error ? 'admin.error' : 'admin.black.700'}
                      defaultValue={parseInt(field.price)}
                    >
                      <NumberInputField textAlign="center" p={{ base: 0, sm: 4 }} />
                    </NumberInput>
                  )}
                  rules={{ required: true, min: 0, validate: (price) => validateNoPoint(price) }}
                />
                <Button
                  w="5rem"
                  h={10}
                  bg="#FFEFEF"
                  color="admin.black"
                  borderRadius="5px"
                  fontWeight="normal"
                  _hover={{
                    color: 'white',
                    bg: 'admin.red.500',
                  }}
                  onClick={() => handleDeleteOption(index)}
                >
                  刪除
                </Button>
              </HStack>
            </Box>
          ))}
        </VStack>
      </Box>
    </CustomDrawer>
  );
};

export default EditModifierDrawer;
