import { FC, ReactElement } from 'react';

import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  SimpleGrid,
} from '@chakra-ui/react';
import type { ChakraProps } from '@chakra-ui/react';

import { ButtonVariants } from '~/customTheme/components/Button';

type ConfirmModalProps = {
  isOpen: boolean;
  content: ReactElement;
  isOnlyConfirm?: boolean;
  modalContentStyles?: ChakraProps;
  modalBodyStyles?: ChakraProps;
  onConfirm: () => void;
  onClose: () => void;
};

const ConfirmModal: FC<ConfirmModalProps> = ({
  isOpen,
  content,
  isOnlyConfirm = false,
  modalContentStyles,
  modalBodyStyles = null,
  onConfirm,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs" isCentered>
      <ModalOverlay />
      <ModalContent {...modalContentStyles} borderRadius="sm">
        <ModalBody py={4} {...modalBodyStyles}>
          {content}

          {isOnlyConfirm ? (
            <Button variant={ButtonVariants.MODAL_PRIMARY} w="full" h={12} onClick={onConfirm}>
              確定
            </Button>
          ) : (
            <SimpleGrid columns={2} spacing={4}>
              <Button variant={ButtonVariants.MODAL_DANGER} h={12} onClick={onClose}>
                取消
              </Button>
              <Button variant={ButtonVariants.MODAL_PRIMARY} h={12} onClick={onConfirm}>
                確定
              </Button>
            </SimpleGrid>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
