import { useEffect, useState } from 'react';
import { recentOrderListener, preOrderListener } from '~/firebase/listener';
import subDays from 'date-fns/subDays';
import { OrderDisplayObj } from '~/components/TodayOrders/type';

/* ---------------------- process order display status ---------------------- */

const useOrders = (selectedStore: string) => {
  const [orders, setOrders] = useState<Array<OrderDisplayObj>>([]);
  const [preOrders, setPreOrders] = useState<Array<OrderDisplayObj>>([]);
  const now = new Date();
  const queryStartDate = subDays(now, parseInt(process.env.REACT_APP_QUERY_PAST_DAY || '1'));

  const handleUpdateOrders = (orders: Array<OrderDisplayObj>) => {
    setOrders(orders);
  };
  const handleUpdatePreOrders = (orders: Array<OrderDisplayObj>) => {
    setPreOrders(orders);
  };

  useEffect(() => {
    handleUpdateOrders([]);
    handleUpdatePreOrders([]);
    const orderListen = recentOrderListener({
      storeId: selectedStore,
      baseDate: queryStartDate,
      orderUpdater: handleUpdateOrders,
    });

    const preOrderListen = preOrderListener({
      storeId: selectedStore,
      baseDate: queryStartDate,
      orderUpdater: handleUpdatePreOrders,
    });

    return () => {
      orderListen();
      preOrderListen();
    };
  }, [selectedStore]);

  const allOrders = [...orders, ...preOrders];

  return { allOrders };
};

export default useOrders;
