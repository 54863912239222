import { FC } from 'react';
import { Flex, Text, Button, Icon } from '@chakra-ui/react';
import { AiOutlinePlus } from 'react-icons/ai';

import { ButtonVariants } from '~/customTheme/components/Button';

type ListOperationProps = {
  title: string;
  btnLabel: string;
  onBtnClick: () => void;
};

const ListOperation: FC<ListOperationProps> = ({ title, btnLabel, onBtnClick }) => {
  return (
    <>
      <Flex justify="space-between" align="center" py={4} mx={{ base: 2, lg: 5 }} bg="white">
        <Text fontSize="lg" fontWeight="bold" letterSpacing="0.2rem">
          {title}
        </Text>
        <Button
          leftIcon={<Icon as={AiOutlinePlus} />}
          variant={ButtonVariants.OUTLINE}
          iconSpacing={1}
          fontSize="md"
          fontWeight="normal"
          borderColor="admin.blue.700"
          borderRadius="5px"
          border="1px dashed"
          onClick={onBtnClick}
        >
          {btnLabel}
        </Button>
      </Flex>
    </>
  );
};

export default ListOperation;
