import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { VStack, Box, useMediaQuery } from '@chakra-ui/react';

import {
  TodayOrderSearch,
  StoresTab,
  StoresSelect,
  TodayOrderTable,
} from '~/components/TodayOrders';
import { useFilterReducer, orderFilter } from '~/components/TodayOrders/utils';
import TitleOperation from '~/components/TitleOperation';

import useOrders from '~/hooks/useOrders';

import { storeListAtom, selectedStoreIdAtom } from '~/recoil/atom';

const TodayOrders = () => {
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
  const storeList = useRecoilValue(storeListAtom);
  const selectedStoreId = useRecoilValue(selectedStoreIdAtom);
  const { allOrders } = useOrders(selectedStoreId);
  const { filterState, filterDispatch } = useFilterReducer();
  const { filteredOrders } = orderFilter(allOrders, filterState);

  return (
    <VStack spacing={4} align="stretch" mb={4}>
      <Box mb="15px" bg="white" pb={4} px={5}>
        <TitleOperation title="今日訂單總覽" isEditable="none" />
      </Box>

      <VStack px={5} align="stretch" spacing={4}>
        <TodayOrderSearch filterDispatch={filterDispatch} />
        {isLargerThan1024 ? (
          <StoresTab storeList={storeList} />
        ) : (
          <StoresSelect storeList={storeList} />
        )}
        <TodayOrderTable filteredOrders={filteredOrders} filterDispatch={filterDispatch} />
      </VStack>
    </VStack>
  );
};

export default TodayOrders;
