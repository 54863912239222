import type { ComponentStyleConfig } from '@chakra-ui/react';

const NumberInput: ComponentStyleConfig = {
  parts: ['field'],
  baseStyle: {
    field: {
      color: 'admin.black',
      paddingInlineStart: 2,
      paddingInlineEnd: 2,
    },
  },
  defaultProps: {
    focusBorderColor: 'admin.black.700',
  },
};

export default NumberInput;
