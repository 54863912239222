import { extendTheme } from '@chakra-ui/react';

import colors from './colors';
import styles from './styles';
import components from './components';
import fonts from './fonts';

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  colors,
  styles,
  components,
  fonts,
});

export default theme;
