import firebase from 'firebase';
import { firebaseFirestore } from './initialize';
import { Menu, Category, Item, Modifier } from '~/types/store';

export const firebaseFieldValue = firebase.firestore.FieldValue;

export const getStoreDocs = async (uid: string) => {
  const storeSnaps = await firebaseFirestore
    .collection('stores')
    .where('admins', 'array-contains', uid)
    .get();
  return storeSnaps.docs;
};

export const addNewMenuInStore = async ({
  storeId,
  newMenuId,
  newMenu,
}: {
  storeId: string;
  newMenuId: string;
  newMenu: Menu;
}) => {
  await firebaseFirestore.doc(`stores/${storeId}`).update({
    [`menus.${newMenuId}`]: newMenu,
  });
};

export const toggleAllowPublishOfMenu = async ({
  storeId,
  menuId,
  value,
}: {
  storeId: string;
  menuId: string;
  value: boolean;
}) => {
  await firebaseFirestore
    .doc(`stores/${storeId}`)
    .update({ [`menus.${menuId}.allowPublish`]: value });
};

export const deleteMenu = async ({ storeId, menuId }: { storeId: string; menuId: string }) => {
  await firebaseFirestore
    .doc(`stores/${storeId}`)
    .update({ [`menus.${menuId}`]: firebaseFieldValue.delete() });
};

export const toggleCategoryInMenu = async ({
  storeId,
  menuId,
  updateObject,
}: {
  storeId: string;
  menuId: string;
  updateObject: firebase.firestore.FieldValue | string[];
}) => {
  await firebaseFirestore
    .doc(`stores/${storeId}`)
    .update({ [`menus.${menuId}.categories`]: updateObject });
};

export const deleteCategory = async ({
  storeId,
  categoryId,
}: {
  storeId: string;
  categoryId: string;
}) => {
  await firebaseFirestore
    .doc(`stores/${storeId}`)
    .update({ [`categories.${categoryId}`]: firebaseFieldValue.delete() });
};

export const copyCategory = async ({
  storeId,
  menuId,
  isIncludeInMenu,
  newCategoryInMenu,
  newCategoryId,
  newCategoryObject,
}: {
  storeId: string;
  menuId: string;
  isIncludeInMenu: boolean;
  newCategoryInMenu: string[];
  newCategoryId: string;
  newCategoryObject: Category;
}) => {
  const updateObject: Record<string, Category | string[]> = {
    [`categories.${newCategoryId}`]: newCategoryObject,
  };
  if (isIncludeInMenu) {
    updateObject[`menus.${menuId}.categories`] = newCategoryInMenu;
  }
  await firebaseFirestore.doc(`stores/${storeId}`).update(updateObject);
};

export const changeOrderOfCategoriesInMenu = async ({
  storeId,
  menuId,
  newCategories,
}: {
  storeId: string;
  menuId: string;
  newCategories: string[];
}) => {
  await firebaseFirestore
    .doc(`stores/${storeId}`)
    .update({ [`menus.${menuId}.categories`]: newCategories });
};

export const addNewCategoryInMenu = async ({
  storeId,
  menuId,
  newCategoriesInMenu,
  newCategoryId,
  newCategory,
}: {
  storeId: string;
  menuId: string;
  newCategoriesInMenu: string[];
  newCategoryId: string;
  newCategory: Category;
}) => {
  await firebaseFirestore.doc(`stores/${storeId}`).update({
    [`menus.${menuId}.categories`]: newCategoriesInMenu,
    [`categories.${newCategoryId}`]: newCategory,
  });
};

export const editCategory = async ({
  storeId,
  categoryId,
  newCategoryName,
}: {
  storeId: string;
  categoryId: string;
  newCategoryName: string;
}) => {
  await firebaseFirestore.doc(`stores/${storeId}`).update({
    [`categories.${categoryId}.name`]: newCategoryName,
  });
};

export const addNewItemInCategory = async ({
  storeId,
  categoryId,
  newItemsInCategory,
  newItemId,
  newItem,
}: {
  storeId: string;
  categoryId: string;
  newItemsInCategory: string[];
  newItemId: string;
  newItem: Item;
}) => {
  await firebaseFirestore.doc(`stores/${storeId}`).update({
    [`categories.${categoryId}.items`]: newItemsInCategory,
    [`items.${newItemId}`]: newItem,
  });
};

export const toggleItemInCategory = async ({
  storeId,
  categoryId,
  updateObject,
}: {
  storeId: string;
  categoryId: string;
  updateObject: firebase.firestore.FieldValue | string[];
}) => {
  await firebaseFirestore
    .doc(`stores/${storeId}`)
    .update({ [`categories.${categoryId}.items`]: updateObject });
};

export const deleteItem = async ({ storeId, itemId }: { storeId: string; itemId: string }) => {
  await firebaseFirestore
    .doc(`stores/${storeId}`)
    .update({ [`items.${itemId}`]: firebaseFieldValue.delete() });
};

export const copyItem = async ({
  storeId,
  categoryId,
  isIncludeInCategory,
  newItemsInCategory,
  newItemId,
  newItemObject,
}: {
  storeId: string;
  categoryId: string;
  isIncludeInCategory: boolean;
  newItemsInCategory: string[];
  newItemId: string;
  newItemObject: Item;
}) => {
  const updateObject: Record<string, Item | string[]> = {
    [`items.${newItemId}`]: newItemObject,
  };
  if (isIncludeInCategory) {
    updateObject[`categories.${categoryId}.items`] = newItemsInCategory;
  }
  await firebaseFirestore.doc(`stores/${storeId}`).update(updateObject);
};

export const changeOrderOfItemsInCategory = async ({
  storeId,
  categoryId,
  newItems,
}: {
  storeId: string;
  categoryId: string;
  newItems: string[];
}) => {
  await firebaseFirestore
    .doc(`stores/${storeId}`)
    .update({ [`categories.${categoryId}.items`]: newItems });
};

export const editItem = async ({
  storeId,
  itemId,
  newItemObject,
}: {
  storeId: string;
  itemId: string;
  newItemObject: Item;
}) => {
  await firebaseFirestore.doc(`stores/${storeId}`).update({ [`items.${itemId}`]: newItemObject });
};

export const toggleModifierInItem = async ({
  storeId,
  itemId,
  updateObject,
}: {
  storeId: string;
  itemId: string;
  updateObject: firebase.firestore.FieldValue | string[];
}) => {
  await firebaseFirestore
    .doc(`stores/${storeId}`)
    .update({ [`items.${itemId}.modifiers`]: updateObject });
};

export const deleteModifier = async ({
  storeId,
  modifierId,
}: {
  storeId: string;
  modifierId: string;
}) => {
  await firebaseFirestore
    .doc(`stores/${storeId}`)
    .update({ [`modifiers.${modifierId}`]: firebaseFieldValue.delete() });
};

export const copyModifier = async ({
  storeId,
  itemId,
  isIncludeInItem,
  newModifiersInItem,
  newModifierId,
  newModifierObject,
}: {
  storeId: string;
  itemId: string;
  isIncludeInItem: boolean;
  newModifiersInItem: string[];
  newModifierId: string;
  newModifierObject: Modifier;
}) => {
  const updateObject: Record<string, Modifier | string[]> = {
    [`modifiers.${newModifierId}`]: newModifierObject,
  };
  if (isIncludeInItem) {
    updateObject[`items.${itemId}.modifiers`] = newModifiersInItem;
  }
  await firebaseFirestore.doc(`stores/${storeId}`).update(updateObject);
};

export const changeOrderOfModifiersInItem = async ({
  storeId,
  itemId,
  newModifiers,
}: {
  storeId: string;
  itemId: string;
  newModifiers: string[];
}) => {
  await firebaseFirestore
    .doc(`stores/${storeId}`)
    .update({ [`items.${itemId}.modifiers`]: newModifiers });
};

export const editModifier = async ({
  storeId,
  modifierId,
  newModifierObject,
}: {
  storeId: string;
  modifierId: string;
  newModifierObject: Modifier;
}) => {
  await firebaseFirestore
    .doc(`stores/${storeId}`)
    .update({ [`modifiers.${modifierId}`]: newModifierObject });
};

export const addNewModifierInItem = async ({
  storeId,
  itemId,
  newModifiersInItem,
  newModifierId,
  newModifier,
}: {
  storeId: string;
  itemId: string;
  newModifiersInItem: string[];
  newModifierId: string;
  newModifier: Modifier;
}) => {
  await firebaseFirestore.doc(`stores/${storeId}`).update({
    [`items.${itemId}.modifiers`]: newModifiersInItem,
    [`modifiers.${newModifierId}`]: newModifier,
  });
};
