import { Text, useDisclosure, VStack, useToast, HStack, Icon } from '@chakra-ui/react';
import { AiOutlineEdit } from 'react-icons/ai';
import { ConfirmModal } from '~/components/UI/Modal';
import { completeOrder } from '~/firebase/order';

const CompleteButton = ({ orderId }: { orderId: string }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const handleConfirm = async () => {
    try {
      await completeOrder({ orderId });
      toast({
        title: '訂單完成',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    } catch (error) {
      console.log(error);
      toast({
        title: '伺服器錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    }
    onClose();
  };

  return (
    <>
      <HStack
        backgroundColor="admin.red.100"
        borderRadius="2px"
        px={2}
        py="2px"
        cursor="pointer"
        onClick={onOpen}
      >
        <Text color="admin.red.700" fontSize="sm">
          編輯狀態
        </Text>
        <Icon as={AiOutlineEdit} color="admin.red.700" />
      </HStack>
      <ConfirmModal
        content={
          <VStack spacing={6} mb={6}>
            <Text mx={5} textAlign="center" fontSize="lg" fontWeight="bold">
              確認訂單已完成？
            </Text>
            <Text textAlign="center">手動調整成訂單已完成</Text>
          </VStack>
        }
        isOpen={isOpen}
        onConfirm={handleConfirm}
        onClose={onClose}
      />
    </>
  );
};

export default CompleteButton;
