// doc https://chakra-ui.com/docs/features/global-styles

const styles = {
  global: {
    body: {
      bg: 'admin.gray.100',
    },
  },
};

export default styles;
