import { Icon, IconProps } from '@chakra-ui/react';

export default function Today(props: IconProps) {
  return (
    <Icon fill="transparent" {...props}>
      <path
        d="M19.5007 3.75098H4.50073C3.25809 3.75098 2.25073 4.75834 2.25073 6.00098V19.501C2.25073 20.7436 3.25809 21.751 4.50073 21.751H19.5007C20.7434 21.751 21.7507 20.7436 21.7507 19.501V6.00098C21.7507 4.75834 20.7434 3.75098 19.5007 3.75098Z"
        stroke="#787878"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M18.6485 3.75098H5.35292C3.64245 3.75098 2.25073 5.16426 2.25073 6.90097V9.00097H2.62573C2.62573 8.25097 3.75073 7.50097 4.50073 7.50097H19.5007C20.2507 7.50097 21.3757 8.25097 21.3757 9.00097H21.7507V6.90097C21.7507 5.16426 20.359 3.75098 18.6485 3.75098Z"
        fill="#787878"
      />
      <path
        d="M6.00006 2.25195V3.75195"
        stroke="#787878"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 2.25195V3.75195"
        stroke="#787878"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.16078 9.75098H5.83922C5.09959 9.75098 4.5 10.3506 4.5 11.0902V14.4118C4.5 15.1514 5.09959 15.751 5.83922 15.751H9.16078C9.90041 15.751 10.5 15.1514 10.5 14.4118V11.0902C10.5 10.3506 9.90041 9.75098 9.16078 9.75098Z"
        fill="#787878"
      />
      <path
        d="M21.7499 12.002V9.75195C21.7482 9.15575 21.5106 8.58446 21.089 8.16287C20.6674 7.74129 20.0961 7.50368 19.4999 7.50195H4.49994C3.90373 7.50368 3.33244 7.74129 2.91086 8.16287C2.48928 8.58446 2.25167 9.15575 2.24994 9.75195V12.002"
        stroke="#787878"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
    </Icon>
  );
}
