import { useEffect } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { useToast } from '@chakra-ui/react';
import { firebaseFirestore } from '~/firebase/initialize';

import { storeAtom, selectedStoreIdAtom, selectedMenuIdAtom } from '~/recoil/atom';
import { StoreWithId } from '~/types/store';

export default function useStore() {
  const setStore = useSetRecoilState(storeAtom);
  const setSelectedMenuId = useSetRecoilState(selectedMenuIdAtom);
  const selectedStoreId = useRecoilValue(selectedStoreIdAtom);
  const toast = useToast();
  useEffect(() => {
    setSelectedMenuId(null);
    if (selectedStoreId === '') {
      setStore(null);
      return;
    }
    const storeListener = firebaseFirestore.doc(`stores/${selectedStoreId}`).onSnapshot(
      (doc) => {
        const data = doc.data();
        setStore({ ...data, id: doc.id } as StoreWithId);
      },
      (error) => {
        toast({
          position: 'top',
          title: '發生錯誤',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setStore(null);
      },
    );
    return () => storeListener();
  }, [selectedStoreId]);
}
