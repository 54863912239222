import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  Text,
  Textarea,
  Box,
  Input,
  HStack,
  useToast,
  NumberInput,
  NumberInputField,
  Button,
} from '@chakra-ui/react';
import { IoEnterOutline } from 'react-icons/io5';
import { useForm, Controller } from 'react-hook-form';
import cuid from 'cuid';

import CustomDrawer from '~/components/UI/Drawer';

import { selectedStoreIdAtom } from '~/recoil/atom';
import { Item } from '~/types/store';
import { NewItem } from './types';

import { addNewItemInCategory } from '~/firebase/store';

import { ButtonVariants } from '~/customTheme/components/Button';

type NewItemProps = {
  isOpen: boolean;
  onClose: () => void;
  items: string[];
  code: string;
};

const NewItemDrawer: React.FC<NewItemProps> = ({ isOpen, onClose, items, code }) => {
  const toast = useToast();

  const { categoryId = '' } = useParams();
  const { register, control, handleSubmit } = useForm<NewItem>({ shouldUnregister: true });
  const storeId = useRecoilValue(selectedStoreIdAtom);

  const validateNoSpace = (name: string) => {
    return name.trim().length !== 0;
  };

  const validateNoPoint = (name: string) => {
    return !name.includes('.');
  };

  const handleSaveNewItem = async (data: NewItem) => {
    if (!storeId) return;
    const newItemId = `${code}${cuid()}`;
    const newItem: Item = {
      ...data,
      code,
      name: data.name.trim(),
      price: parseInt(data.price),
      image: data.image?.trim() || '',
      modifiers: [],
      tax: 0,
      isSoldOut: false,
      inventory: {
        currentInventory: Infinity,
        defaultInventory: Infinity,
      },
    };
    const newItemsInCategory = [newItemId, ...items];
    try {
      await addNewItemInCategory({ storeId, categoryId, newItemsInCategory, newItemId, newItem });
      toast({
        position: 'top',
        title: `已新增品項`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleOpenImageUrl = () => {
    window.open('https://weserve-gm.sanity.studio/desk/mallImage');
  };

  return (
    <CustomDrawer
      title="品項詳細頁"
      isOpen={isOpen}
      onClose={onClose}
      onSave={handleSubmit(handleSaveNewItem)}
    >
      <Box as="label" justifyContent="space-between">
        <Text mb="0.2rem" htmlFor="name" color="#8F8F8F" fontSize="md">
          品項名稱
        </Text>
        <Controller
          control={control}
          name="name"
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              isInvalid={!!error}
              errorBorderColor="admin.error"
              focusBorderColor={!!error ? 'admin.error' : 'admin.black.700'}
            />
          )}
          rules={{ required: true, validate: (name) => validateNoSpace(name) }}
        />
      </Box>

      <Box as="label" justifyContent="space-between">
        <Text mb="0.2rem" htmlFor="price" color="#8F8F8F" fontSize="md">
          品項價錢
        </Text>
        <Controller
          control={control}
          name="price"
          render={({ field, fieldState: { error } }) => (
            <NumberInput
              {...field}
              isInvalid={!!error}
              errorBorderColor="admin.error"
              focusBorderColor={!!error ? 'admin.error' : 'admin.black.700'}
            >
              <NumberInputField />
            </NumberInput>
          )}
          rules={{ required: true, min: 0, validate: (price) => validateNoPoint(price) }}
        />
      </Box>

      <Box as="label" justifyContent="space-between">
        <Text mb="0.2rem" htmlFor="description" color="#8F8F8F" fontSize="md">
          品項敘述
        </Text>
        <Textarea {...register('description')} />
      </Box>

      <Box as="label" justifyContent="space-between">
        <Text mb="0.2rem" htmlFor="image" color="#8F8F8F" fontSize="md">
          品項圖片連結
        </Text>
        <Controller
          control={control}
          name="image"
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <Input {...field} isInvalid={!!error} errorBorderColor="admin.error" />
          )}
        />
      </Box>
      <HStack justify="flex-end">
        <Button
          variant={ButtonVariants.MODAL_PRIMARY}
          fontSize="sm"
          fontWeight="normal"
          w="8rem"
          rightIcon={<IoEnterOutline />}
          onClick={handleOpenImageUrl}
        >
          圖片上傳連結
        </Button>
      </HStack>
    </CustomDrawer>
  );
};

export default NewItemDrawer;
