const colors = {
  admin: {
    primary: '#C8161D',
    warning: '#FAAD14',
    success: '#52C41A',
    error: '#F5222D',
    gray: {
      800: '#787878',
      600: '#A4A4A4',
      300: '#C4C4C4',
      200: '#F2F2F2',
      100: '#FAFAFA',
      50: '#EEEEEE',
    },
    red: {
      700: '#E83B3B',
      100: '#FEF5F6',
    },
    black: {
      1000: '#000000',
      800: '#070707',
      700: '#232323',
      600: '#1B1B1B',
      500: '#131313',
      400: '#333333',
    },
  },
  // https://stackoverflow.com/questions/64951508/chakra-ui-colorscheme-prop-on-button
  switch: {
    500: '#333333',
  },
  tableStatus: {
    500: '#333333',
  },
  checkbox: {
    500: '#C8161D',
  },
  radio: {
    500: '#C8161D',
  },
};

export default colors;
