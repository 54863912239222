import React, { FC } from 'react';

import PageNumber from './PageNumber';
import Ellipsis from './Ellipsis';

type PageContentProps = {
  pages: number[];
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

const PageContent: FC<PageContentProps> = ({
  pages,
  currentPage,
  setCurrentPage,
}) => {
  // show all eight pages
  if (pages.length < 8) {
    return (
      <>
        {pages.map((page) => (
          <PageNumber
            key={page}
            page={page}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ))}
      </>
    );
  }

  // show first page and show last three pages
  if (pages.length - 2 <= currentPage) {
    return (
      <>
        <PageNumber
          page={pages[0]}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />

        <Ellipsis
          position='left'
          pages={pages}
          setCurrentPage={setCurrentPage}
        />

        {pages.slice(pages.length - 4, pages.length).map((page) => (
          <PageNumber
            key={page}
            page={page}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ))}
      </>
    );
  }

  // show first page and show last four pages
  if (pages.length - 3 <= currentPage) {
    return (
      <>
        <PageNumber
          page={1}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />

        <Ellipsis
          position='left'
          pages={pages}
          setCurrentPage={setCurrentPage}
        />

        {pages.slice(pages.length - 5, pages.length).map((page) => (
          <PageNumber
            key={page}
            page={page}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ))}
      </>
    );
  }

  // show three middle pages
  if (pages.length - 3 > currentPage && currentPage > 4) {
    return (
      <>
        <PageNumber
          page={pages[0]}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />

        <Ellipsis
          position='left'
          pages={pages}
          setCurrentPage={setCurrentPage}
        />

        {pages.slice(currentPage - 1, currentPage + 2).map((page) => (
          <PageNumber
            key={page}
            page={page}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ))}

        <Ellipsis
          position='right'
          pages={pages}
          setCurrentPage={setCurrentPage}
        />

        <PageNumber
          key={pages.length}
          page={pages.length}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </>
    );
  }

  // show first three pages
  if (currentPage < 4) {
    return (
      <>
        {pages.slice(0, 4).map((page) => (
          <PageNumber
            key={page}
            page={page}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ))}

        <Ellipsis
          position='right'
          pages={pages}
          setCurrentPage={setCurrentPage}
        />

        <PageNumber
          key={pages.length}
          page={pages.length}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </>
    );
  }

  // show first four pages
  if (currentPage < 5) {
    return (
      <>
        {pages.slice(0, 5).map((page) => (
          <PageNumber
            key={page}
            page={page}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ))}

        <Ellipsis
          position='right'
          pages={pages}
          setCurrentPage={setCurrentPage}
        />

        <PageNumber
          key={pages.length}
          page={pages.length}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </>
    );
  }

  return null;
};

export default PageContent;
