import React from 'react';
import { IoSwapVerticalOutline } from 'react-icons/io5';
import { HStack, Divider, Button, Icon } from '@chakra-ui/react';
import { ButtonVariants } from '~/customTheme/components/Button';

type OperationButtonGroupProps = {
  onClickCopy: () => void;
  onClickDelete: () => void;
  isInclude: boolean;
};

const OperationButtonGroup: React.FC<OperationButtonGroupProps> = ({
  onClickCopy,
  onClickDelete,
  isInclude,
}) => {
  return (
    <HStack spacing={{ base: 1, lg: 2 }} divider={<Divider orientation="vertical" />}>
      <Button variant={ButtonVariants.TEXT} fontSize="15px" onClick={onClickCopy}>
        複製
      </Button>
      <Button variant={ButtonVariants.TEXT} fontSize="15px" onClick={onClickDelete}>
        刪除
      </Button>
      {isInclude && <Icon as={IoSwapVerticalOutline} cursor="pointer" />}
    </HStack>
  );
};

export default OperationButtonGroup;
