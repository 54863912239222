import type { FC, ReactElement } from 'react';

import { HStack, Text, VStack } from '@chakra-ui/react';
import { OrderModifier } from '../type';

export type OrderDetailItemFieldProps = {
  count: number;
  name: string;
  price: number;
  modifiers: Array<OrderModifier>;
  note: string;
};

const OrderDetailItemField: FC<OrderDetailItemFieldProps> = ({
  count,
  name,
  price,
  modifiers,
  note,
}) => {
  return (
    <HStack spacing={2} align="flex-start" justify="space-between" mt={2}>
      <HStack w="2rem">
        <Text color="#333333" fontSize="sm">
          {count} X
        </Text>
      </HStack>

      <VStack flex={1} align="stretch">
        <HStack spacing={3} align="flex-start" justify="space-between">
          <Text color="#333333" fontSize="sm">
            {name}
          </Text>
          <Text color="#333333" fontSize="sm">
            NT${price}
          </Text>
        </HStack>
        <VStack align="stretch" justify="space-between" spacing={3}>
          {modifiers.map(({ options }) => {
            options.map(({ name, price }) => (
              <HStack spacing={3} align="flex-start" justify="space-between">
                <Text color="#747474" fontSize="xs">
                  {name}
                </Text>
                {price !== 0 && (
                  <Text color="#747474" fontSize="xs">
                    NT${price}
                  </Text>
                )}
              </HStack>
            ));
          })}
          <HStack align="flex-start">
            <Text color="#747474" fontSize="xs">
              {note}
            </Text>
          </HStack>
        </VStack>
      </VStack>
    </HStack>
  );
};

export default OrderDetailItemField;
