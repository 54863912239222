import { addDays, startOfWeek, format } from 'date-fns';

type GetDateList = (
  year: number,
  month: number,
) => {
  formatted: string;
  time: number;
  day: number;
  month: number;
  year: number;
  isThisMonth: boolean;
}[];

export const getDateList: GetDateList = (year, month) => {
  const dayList = Array.from({ length: 42 }, () => ({
    day: 1,
    year: year,
    month: month,
    isThisMonth: true,
  }));

  // the month of Date's start is 0, so substract 1 from month
  const transferredMonth = month - 1;

  const startDate = startOfWeek(new Date(year, transferredMonth, 1));

  return dayList.map((_, index) => {
    const currentDay = addDays(startDate, index);

    return {
      formatted: format(currentDay, 'yyyy/MM/dd'),
      time: currentDay.getTime(),
      day: currentDay.getDate(),
      month: currentDay.getMonth(),
      year: currentDay.getFullYear(),
      isThisMonth: currentDay.getMonth() === transferredMonth,
    };
  });
};
