import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Center,
  Checkbox,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import cuid from 'cuid';

import { ConfirmModal } from '~/components/UI/Modal';
import Pagination from '~/components/UI/Pagination';
import OperationButtonGroup from '~/components/UI/OperationButtonGroup';
import { EditModifierDrawer } from '~/components/OperationDrawer/Modifier';
import { selectedStoreIdAtom } from '~/recoil/atom';
import { modifiersSelector } from '~/recoil/selector/modifier';
import { ItemWithId, ModifierWithId } from '~/types/store';
import {
  toggleModifierInItem,
  deleteModifier,
  copyModifier,
  changeOrderOfModifiersInItem,
  firebaseFieldValue,
} from '~/firebase/store';

import omit from 'lodash/omit';

const ModifierListTable = ({ item }: { item: ItemWithId }) => {
  // chakra hook
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const {
    isOpen: isOpenEditModifier,
    onOpen: onOpenEditModifier,
    onClose: onCloseEditModifier,
  } = useDisclosure();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [editModifier, setEditModifier] = useState<ModifierWithId | null>(null);

  const [readyToDeleteModifier, setReadyToDeleteModifier] = useState<{
    id: string;
    name: string;
  } | null>(null);

  useEffect(() => {
    setCurrentPage(1);
  }, [item]);

  const storeId = useRecoilValue(selectedStoreIdAtom);

  const { id: itemId = '', modifiers: modifiersInItem = [] } = item;
  const modifiers = useRecoilValue(modifiersSelector);

  const modifiersNotInItem = Object.keys(omit(modifiers, modifiersInItem));
  const allModifiers = [...modifiersInItem, ...modifiersNotInItem];

  const displayModifiers = allModifiers.slice((currentPage - 1) * 10, currentPage * 10);

  const handleToggleInItem = async (targetId: string) => {
    const isInclude = modifiersInItem.includes(targetId);
    const updateObject = isInclude
      ? firebaseFieldValue.arrayRemove(targetId)
      : [targetId, ...modifiersInItem];
    try {
      await toggleModifierInItem({ storeId, itemId, updateObject });
      toast({
        position: 'top',
        title: '操作成功',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleOpenDeleteModal = (id: string, name: string) => {
    setReadyToDeleteModifier({ id: id, name: name });
    onOpen();
  };

  const handleConfirmDelete = async () => {
    if (readyToDeleteModifier === null) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      await deleteModifier({ storeId, modifierId: readyToDeleteModifier.id });
      toast({
        position: 'top',
        title: `「${readyToDeleteModifier.name}」已刪除`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    setReadyToDeleteModifier(null);
    onClose();
  };

  const handleOpenEditModifier = (id: string) => {
    setEditModifier({ id, ...modifiers[id] });
    onOpenEditModifier();
  };

  const handleCloseEditModifier = () => {
    setEditModifier(null);
    onCloseEditModifier();
  };

  const handleCopy = async (modifier: ModifierWithId) => {
    const newModifierId = cuid();
    let { id, ...restOfModifier } = modifier;
    restOfModifier.name = `${modifier.name}（1）`;

    const isIncludeInItem = modifiersInItem.includes(id);
    const newItemModifiers = isIncludeInItem ? [...modifiersInItem] : [];
    if (isIncludeInItem) {
      const index = modifiersInItem.indexOf(id) + 1;
      newItemModifiers.splice(index, 0, newModifierId);
    }

    try {
      await copyModifier({
        storeId,
        itemId,
        isIncludeInItem,
        newModifierId,
        newModifiersInItem: newItemModifiers,
        newModifierObject: restOfModifier,
      });
      toast({
        position: 'top',
        title: `「${modifier.name}」已複製`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDragEnd = async ({ draggableId: modifierId, source, destination }: DropResult) => {
    if (!destination || source.index === destination.index) {
      return;
    }
    const sourceIndex = source.index + (currentPage - 1) * 10;
    const destinationIndex = destination.index + (currentPage - 1) * 10;
    const newModifiersInItems = [...modifiersInItem];
    newModifiersInItems.splice(sourceIndex, 1);
    newModifiersInItems.splice(destinationIndex, 0, modifierId);
    try {
      await changeOrderOfModifiersInItem({ storeId, itemId, newModifiers: newModifiersInItems });
      toast({
        position: 'top',
        title: '操作成功',
        status: 'success',
        duration: 1000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: 'top',
        title: '發生錯誤',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const SingleLine = (modifier: ModifierWithId) => (
    <>
      <Td px={{ base: 2, lg: 4 }} textAlign="center">
        <Checkbox
          size="lg"
          isChecked={modifiersInItem.includes(modifier.id)}
          onChange={() => handleToggleInItem(modifier.id)}
          colorScheme="tableStatus"
        />
      </Td>
      <Td px={{ base: 2, lg: 4 }}>
        <Text
          cursor="pointer"
          onClick={() => handleOpenEditModifier(modifier.id)}
          wordBreak="break-all"
          color="admin.blue.700"
        >
          {modifier.name}
        </Text>
      </Td>
      <Td px={{ base: 2, lg: 4 }}>
        <OperationButtonGroup
          isInclude={modifiersInItem.includes(modifier.id)}
          onClickCopy={() => handleCopy(modifier)}
          onClickDelete={() => handleOpenDeleteModal(modifier.id, modifier.name)}
        />
      </Td>
    </>
  );

  return (
    <>
      {allModifiers.length ? (
        <>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr h={10}>
                <Th w="20%" textAlign="center" px={{ base: 2, lg: 4 }}>
                  勾選
                </Th>
                <Th w="55%" px={{ base: 2, lg: 4 }}>
                  選項名稱
                </Th>
                <Th w="25%" px={{ base: 2, lg: 4 }}>
                  操作
                </Th>
              </Tr>
            </Thead>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <Tbody bg="white" {...provided.droppableProps} ref={provided.innerRef}>
                    {displayModifiers.map((modifierId, index) =>
                      modifiers[modifierId] ? (
                        <Draggable
                          key={modifierId}
                          draggableId={modifierId}
                          index={index}
                          isDragDisabled={modifiersNotInItem.includes(modifierId)}
                        >
                          {(provided) => (
                            <Tr
                              h={14}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{ cursor: 'default', ...provided.draggableProps.style }}
                            >
                              {SingleLine({ id: modifierId, ...modifiers[modifierId] })}
                            </Tr>
                          )}
                        </Draggable>
                      ) : (
                        <></>
                      ),
                    )}
                    {provided.placeholder}
                  </Tbody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            maximumPageNumber={Math.ceil(allModifiers.length / 10)}
          />
        </>
      ) : (
        <Center h={16}>
          <Text>暫無選項</Text>
        </Center>
      )}
      <ConfirmModal
        isOpen={isOpen}
        content={
          <Text pb={7} textAlign="center" fontSize="xl" fontWeight="bold" lineHeight="24px">
            確定刪除品項「{readyToDeleteModifier?.name}」？
          </Text>
        }
        modalContentStyles={{ maxW: '380px' }}
        modalBodyStyles={{ pt: 7, pb: 5, px: '30px' }}
        onConfirm={handleConfirmDelete}
        onClose={onClose}
      />
      <EditModifierDrawer
        isOpen={isOpenEditModifier}
        onClose={handleCloseEditModifier}
        modifier={editModifier}
      />
    </>
  );
};

export default ModifierListTable;
