import { useState } from 'react';
import { Input, Button, InputGroup, InputRightElement, Icon, Stack } from '@chakra-ui/react';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import { ButtonVariants } from '~/customTheme/components/Button';

type SearchInputProps = {
  placeholder: string;
  onSearch: (search: string) => void;
};

const SearchInput: React.FC<SearchInputProps> = ({ placeholder, onSearch }) => {
  const [input, setInput] = useState<string>('');
  const handleSearch = () => {
    onSearch(input);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      onSearch(input);
    }
  };

  return (
    <Stack direction={['column', 'row']}>
      <InputGroup>
        <Input
          minW={260}
          borderRadius={5}
          borderColor="admin.gray.300"
          type="text"
          placeholder={placeholder}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyPress}
          _placeholder={{ color: 'admin.gray.300' }}
        />
        <InputRightElement
          children={<Icon as={AiOutlineCloseCircle} color="admin.gray.600" />}
          onClick={() => {
            setInput('');
            onSearch('');
          }}
        />
      </InputGroup>
      <Button
        variant={ButtonVariants.SOLID}
        h="full"
        lineHeight="10"
        fontWeight="normal"
        px={8}
        borderRadius={5}
        onClick={handleSearch}
      >
        搜尋
      </Button>
    </Stack>
  );
};

export default SearchInput;
