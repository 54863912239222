import type { ComponentStyleConfig } from '@chakra-ui/react';

const Heading: ComponentStyleConfig = {
  baseStyle: {
    color: 'admin.black',
    fontWeight: 'bold',
  },
  sizes: {
    // change default
    xl: {
      fontSize: '2xl',
    },
  },
};

export default Heading;
