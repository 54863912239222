import { useNavigate } from 'react-router';
import { Box, useDisclosure, useMediaQuery, Flex, Text } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';

import { selectedStoreIdAtom, storeListAtom } from '~/recoil/atom';

import { StoresTab, StoresSelect } from '~/components/TodayOrders';
import { MenuListTable } from '~/components/MenuList';
import { NewMenuDrawer } from '~/components/OperationDrawer/Menu';
import ListOperation from '~/components/ListOperation';
import TitleOperation from '~/components/TitleOperation';

const MenuList = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
  const navigate = useNavigate();
  const storeList = useRecoilValue(storeListAtom);
  const selectedStoreId = useRecoilValue(selectedStoreIdAtom);

  if (selectedStoreId === '') {
    navigate('/storeList');
  }
  return (
    <>
      <Box mb="15px" bg="white" p={{ base: '14px', lg: '45px 25px 22px 25px' }}>
        <TitleOperation title="專櫃菜單管理" isEditable="none" />
      </Box>
      <Flex direction="column" m={5} bg="white">
        <Text
          fontSize="lg"
          fontWeight="bold"
          lineHeight="22px"
          letterSpacing="0.1em"
          px={4}
          pt={22}
        >
          點選要觀看的門市
        </Text>
        {isLargerThan1024 ? (
          <StoresTab storeList={storeList} />
        ) : (
          <StoresSelect storeList={storeList} />
        )}
      </Flex>

      <Box mx={5} bg="white" overflowX="auto">
        <ListOperation title="專櫃列表" btnLabel="新增專櫃" onBtnClick={onOpen} />
        <MenuListTable />
      </Box>
      <NewMenuDrawer isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default MenuList;
