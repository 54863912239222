import React, { FC } from 'react';
import {
  chakra,
  Center,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

import type { HTMLChakraProps } from '@chakra-ui/react';
import type { HTMLMotionProps } from 'framer-motion';

type Merge<P, T> = Omit<P, keyof T> & T;
type MotionBoxProps = Merge<HTMLChakraProps<'div'>, HTMLMotionProps<'div'>>;

const MotionBox: React.FC<MotionBoxProps> = motion(chakra.div);

type LoadingModalProps = {
  isVisible: boolean;
};

type MotionBoxStyleFactory = (delay: number) => MotionBoxProps;

const LoadingModal: FC<LoadingModalProps> = ({ isVisible }) => {
  const motionBoxStyleFactory: MotionBoxStyleFactory = (delay) => ({
    w: 5,
    h: 5,
    borderRadius: 'full',
    animate: {
      translateY: [-20, 20, -20],
    },
    transition: {
      duration: 1,
      ease: 'easeInOut',
      repeat: Infinity,
      repeatType: 'loop',
      repeatDelay: 0,
      delay,
    },
  });

  return (
    <Modal size='xs' isOpen={isVisible} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody boxShadow='0px 0px 10px rgba(8, 87, 195, 0.3)'>
          <Center h={20}>
            <HStack spacing={3} h='full'>
              <MotionBox {...motionBoxStyleFactory(0.1)} bg='#3888FF' />
              <MotionBox
                {...motionBoxStyleFactory(0.2)}
                bgGradient='linear(to-r, #3888FF 6.17%, #70C8FE 90.74%)'
              />
              <MotionBox {...motionBoxStyleFactory(0.3)} bg='#70C8FE' />
            </HStack>
          </Center>
          <Text textAlign='center'>Loading...</Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoadingModal;
