import React from 'react';
import { Center, Icon } from '@chakra-ui/react';
import { AiOutlineUp } from 'react-icons/ai';

const GoTop = () => {
  const handleGoTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Center
      position='fixed'
      bottom={4}
      right={4}
      w={7}
      h={7}
      bg='admin.primary'
      borderRadius='base'
      cursor='pointer'
      onClick={handleGoTop}
    >
      <Icon as={AiOutlineUp} color='white' />
    </Center>
  );
};

export default GoTop;
