import { selectorFamily, selector } from 'recoil';
import { ItemWithId, Item } from '~/types/store';
import pickBy from 'lodash/pickBy';
import { storeAtom, selectedMenuIdAtom } from '../atom';

const itemsSelector = selector<Record<string, Item>>({
  key: 'items',
  get: ({ get }) => {
    const store = get(storeAtom);
    const menuId = get(selectedMenuIdAtom);
    if (!store || !menuId) return {};
    const targetCode = store.menus[menuId].code;
    return pickBy(store.items, (item) => item.code === targetCode);
  },
});

const singleItemSelector = selectorFamily<ItemWithId | null, string>({
  key: 'item',
  get:
    (targetId) =>
    ({ get }) => {
      const store = get(storeAtom);
      if (!store) return null;
      return store.items[targetId] ? { ...store.items[targetId], id: targetId } : null;
    },
});

export { itemsSelector, singleItemSelector };
